import React from "react";
import Meta from "../../components/Meta";
import { requireProUser } from "../../util/auth";
import { AngleRegenerator } from "../../components/regenerator/AngleRegenerator";

function AngleRegeneratorPage(props) {
  return (
    <>
      <Meta title="Angle Regenerator" />
      <AngleRegenerator />
    </>
  );
}

export default requireProUser(AngleRegeneratorPage);
