import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Typography,
  CircularProgress,
  Grid,
  IconButton,
  Container,
  Divider,
  makeStyles,
  Paper,
  FormControl,
  Select,
  MenuItem,
} from '@material-ui/core';
import { useAuth } from "../../util/auth";
import Section from "../Section";
import SectionHeader from "../SectionHeader";
import { TextEditor } from "../richtexteditor/TextEditor";
import { Toaster, toast } from "react-hot-toast";
import AutorenewIcon from '@material-ui/icons/Autorenew';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import GetAppIcon from '@material-ui/icons/GetApp';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import CreatableSelect from 'react-select/creatable';
import { retrieveProfiles, loadProfileData } from '../../util/db.js';
import { fetchDataWithoutTyping } from "../functionalComponent/fetchData";

const useStyles = makeStyles((theme) => ({
  videoSection: {
    backgroundColor: '#000',
    borderRadius: theme.shape.borderRadius,
    overflow: 'hidden',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  video: {
    maxWidth: '100%',
    maxHeight: '100%',
    objectFit: 'contain',
  },
  scriptSection: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  scriptContent: {
    flex: 1,
    padding: theme.spacing(2),
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    overflowY: 'auto',
    backgroundColor: theme.palette.background.paper,
    '& p': {
      marginBottom: theme.spacing(1),
    },
  },
  regenerateButton: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.success.dark,
    },
  },
}));

const MemoizedSelect = React.memo(({ value, onChange, options, ...props }) => (
  <CreatableSelect
    value={value}
    onChange={onChange}
    options={options}
    {...props}
  />
));

export function VideoRegenerator() {
  const classes = useStyles();
  const auth = useAuth();
  const [selectedProfile, setSelectedProfile] = useState(null);
  const [profiles, setProfiles] = useState([]);
  const [videoUrl, setVideoUrl] = useState('');
  const [script, setScript] = useState('');
  const [isTranscribing, setIsTranscribing] = useState(false);
  const [isRegenerating, setIsRegenerating] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [editorContent, setEditorContent] = useState('');
  const [selectedLanguage, setSelectedLanguage] = useState("Chinese");
  const [isGeneratingIdeas, setIsGeneratingIdeas] = useState(false);
  const [isProcessingFile, setIsProcessingFile] = useState(false);
  const [videoDataUrl, setVideoDataUrl] = useState('');
  const [uploadedFileName, setUploadedFileName] = useState('');

  useEffect(() => {
    if (auth.user) {
      retrieveProfiles(auth.user.uid).then(setProfiles);
    }
  }, [auth.user]);

  useEffect(() => {
    setEditorContent(script);
  }, [script]);

  const handleContentChange = (content) => {
    setEditorContent(content);
    setScript(content);
  };

  const handleTranscribe = async () => {
    if (!videoUrl && !videoDataUrl) {
      toast.error('Please provide a video URL');
      return;
    }

    setIsTranscribing(true);
    try {
      const response = await fetch("https://ctg-server-3.fly.dev/convert", {
        method: "POST",
        credentials: 'include',
        mode: 'cors',
        body: JSON.stringify({ 
          videoUrl: videoDataUrl || videoUrl, 
          uid: auth.user.uid 
        }),
        headers: {
          "Content-Type": "application/json",
          "Accept": "application/json",
        },
      });

      const transcriptionData = await response.json();
      if (transcriptionData && transcriptionData.transcription) {
        setScript(transcriptionData.transcription);
        toast.success('Transcription complete!');
      }
    } catch (error) {
      console.error("Failed to transcribe video:", error);
      toast.error('Failed to transcribe video');
    } finally {
      setIsTranscribing(false);
    }
  };


  const handleRegenerateScript = async () => {
    if (!selectedProfile) {
      toast.error('Please select a profile first');
      return;
    }
    
    if (!editorContent) {
      toast.error('Please transcribe the video first');
      return;
    }
    console.log("editorContent", editorContent)
    console.log("script", script)

    setIsRegenerating(true);
    try {
      const profileData = await loadProfileData(auth.user.uid, selectedProfile.value);
      
      const query = `Original Script: ${editorContent}. 
      Product Information: Product Name: ${profileData.productName}, 
      Product USP: ${profileData.productUSP}, 
      Product Ingredients: ${profileData.productIngredients}, 
      Product Content: ${profileData.productContent},
      Product Certification: ${profileData.productCert}`;
      
      let id;
      if (selectedLanguage === "Chinese") {
        id = "script-regenerate-cn"
      } else if (selectedLanguage === "English") {
        id = "script-regenerate-en"
      } else if (selectedLanguage === "Malay") {
        id = "script-regenerate-malay"
      }

      const payload = {
        id,
        query,
        credit: 0.5
      };

      const newScript = await toast.promise(
        fetchDataWithoutTyping(payload, auth),
        {
          loading: "Regenerating script...",
          success: "Script regenerated!",
          error: "Failed to regenerate script.",
        }
      );
  
      const formattedScript = newScript
      .split('\n')
      .filter(line => line.trim())
      .map(line => `<p>${line}</p>`)
      .join('');

      // Format and append to existing content with clear separation
      const separator = `
        <div style="text-align: center; font-weight: bold; margin: 20px 0;">
          ============== New Version ==============
        </div>
      `;
      
      const updatedContent = editorContent + separator + formattedScript;
      
      // Update both states with the new content
      setScript(updatedContent);
      setEditorContent(updatedContent);
    } catch (error) {
      console.error('Error regenerating script:', error);
      toast.error(error.message || 'Failed to regenerate script');
    } finally {
      setIsRegenerating(false);
    }
  };

  const handleGenerateMoreIdeas = async () => {
    if (!selectedProfile) {
      toast.error('Please select a profile first');
      return;
    }
  
    if (!script) {
      toast.error('Please transcribe the video first');
      return;
    }
  
    setIsGeneratingIdeas(true);
    try {
      const profileData = await loadProfileData(auth.user.uid, selectedProfile.value);
      
      const query = `Generate 4 completely different video script hook ideas for this product. 
      Each idea should have a unique approach and emotional appeal.
      
      Product Details:
      - Name: ${profileData.productName}
      - USP: ${profileData.productUSP}
      - Ingredients: ${profileData.productIngredients}
      - Key Features: ${profileData.productContent}
      - Certifications: ${profileData.productCert}
  
      Previous content to avoid repeating (generate completely different angles):
      ${script}
  
      Requirements:
      1. Each idea should have a distinct sales angle
      2. Include emotional hooks and call-to-actions
      3. Focus on different benefits or features
      4. Use varied storytelling styles
      5. Format each idea with a clear title and content`;
  
      let id;
      if (selectedLanguage === "Chinese") {
        id = "video-idea-generator-cn"
      } else if (selectedLanguage === "English") {
        id = "video-idea-generator-en"
      } else if (selectedLanguage === "Malay") {
        id = "video-idea-generator-malay"
      }
  
      const credit = 0.5;
      const payload = { id, query, credit };
  
      const newIdeas = await toast.promise(
        fetchDataWithoutTyping(payload, auth),
        {
          loading: "Generating new ideas...",
          success: "New ideas generated!",
          error: "Failed to generate ideas.",
        }
      );
  
      // Format the response with proper HTML paragraphs
      const formattedIdeas = newIdeas
        .split('\n')
        .filter(line => line.trim())
        .map(line => `<p>${line}</p>`)
        .join('');
  
      // Format and append to regenerated content with clear separation
      const separator = `
        <div style="text-align: center; font-weight: bold; margin: 20px 0;">
          ============== More Ideas ==============
        </div>
      `;
      
      setScript(prev => prev + separator + formattedIdeas);
  
    } catch (error) {
      console.error('Error generating ideas:', error);
      toast.error(error.message || 'Failed to generate ideas');
    } finally {
      setIsGeneratingIdeas(false);
    }
  };

  const handleFileUpload = async (event) => {
    const file = event.target.files?.[0];
    if (!file) return;
  
    if (!file.type.startsWith('video/')) {
      toast.error('Please upload a video file');
      return;
    }
  
    if (file.size > 100 * 1024 * 1024) {
      toast.error('File size should be less than 100MB');
      return;
    }
  
    setIsProcessingFile(true);
    try {
      const dataUrl = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.readAsDataURL(file);
      });
      
      setVideoDataUrl(dataUrl);
      setVideoUrl(''); // Clear normal URL when file is uploaded
      setUploadedFileName(file.name);
      toast.success('Video loaded successfully');
    } catch (error) {
      console.error('Error processing file:', error);
      toast.error('Failed to process video file');
    } finally {
      setIsProcessingFile(false);
    }
  };

  const handleCopyContent = async (content) => {
    try {
      // Format content similar to how we generate it
      const formattedContent = content
        .replace(/<p>/g, '') // Remove opening p tags
        .replace(/<\/p>/g, '\n') // Convert closing p tags to newlines
        .replace(/<div[^>]*>/g, '\n') // Convert div openings to newlines
        .replace(/<\/div>/g, '\n') // Convert div closings to newlines
        .replace(/<br\s*\/?>/g, '\n') // Convert br tags to newlines
        .replace(/&nbsp;/g, ' ') // Convert &nbsp; to spaces
        .replace(/<[^>]*>/g, '') // Remove any remaining HTML tags
        .replace(/\n\s*\n/g, '\n\n') // Remove excessive blank lines
        .replace(/^\s+|\s+$/g, '') // Trim whitespace
        .replace(/•/g, '  •') // Add spacing before bullets
        .replace(/=+/g, '='); // Preserve separator lines
  
      // Create blob with both HTML and plain text versions
      const htmlContent = `
        <html>
          <body>
            <pre style="font-family: Arial, sans-serif; white-space: pre-wrap;">${formattedContent}</pre>
          </body>
        </html>
      `;
  
      // Create ClipboardItem with both formats
      const clipboardItem = new ClipboardItem({
        'text/html': new Blob([htmlContent], { type: 'text/html' }),
        'text/plain': new Blob([formattedContent], { type: 'text/plain' })
      });
  
      await navigator.clipboard.write([clipboardItem]);
      toast.success('Content copied to clipboard!');
    } catch (err) {
      console.error('Failed to copy:', err);
      // Fallback to basic text copying
      try {
        // Use the same formatting for fallback
        const plainText = content
          .replace(/<p>/g, '')
          .replace(/<\/p>/g, '\n')
          .replace(/<div[^>]*>/g, '\n')
          .replace(/<\/div>/g, '\n')
          .replace(/<br\s*\/?>/g, '\n')
          .replace(/&nbsp;/g, ' ')
          .replace(/<[^>]*>/g, '')
          .replace(/\n\s*\n/g, '\n\n')
          .replace(/^\s+|\s+$/g, '')
          .replace(/•/g, '  •')
          .replace(/=+/g, '=');
  
        await navigator.clipboard.writeText(plainText);
        toast.success('Content copied to clipboard (plain text)!');
      } catch (fallbackErr) {
        toast.error('Failed to copy content');
      }
    }
  };

  const handleDownload = () => {
    if (!script) {
      toast.error('No script to download');
      return;
    }

    const blob = new Blob([script], { type: 'text/plain' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `script_${Date.now()}.txt`;
    a.click();
    window.URL.revokeObjectURL(url);
    toast.success('Script downloaded successfully!');
  };

  return (
    <Section>
      <Container>
        <SectionHeader
          title="Video Script Regenerator"
          subtitle="Transcribe and regenerate your video scripts with AI"
          size={4}
          textAlign="center"
        />
        <Toaster position="bottom-right" reverseOrder={false} />

        <Box mb={3}>
          <MemoizedSelect
            isClearable
            placeholder="Select profile..."
            options={profiles}
            value={selectedProfile}
            onChange={setSelectedProfile}
          />
        </Box>

        <Grid container spacing={4}>
          {/* Left Grid - Video Section */}
          <Grid item xs={12} md={6}>
            <Paper style={{ minHeight: '300px', height: '550px' }}>
              <Box display="flex" justifyContent="space-between" alignItems="center" p={2}>
                <Typography variant="h5">Video</Typography>
              </Box>
              <Divider />
              <Box p={2}>
                <input
                  type="file"
                  accept="video/*"
                  onChange={handleFileUpload}
                  disabled={isProcessingFile}
                  style={{ marginTop: '8px' }}
                />
                <input
                  type="text"
                  placeholder="Enter video URL"
                  value={videoDataUrl ? uploadedFileName : videoUrl}
                  onChange={(e) => {
                    setVideoUrl(e.target.value);
                    setVideoDataUrl(''); // Clear data URL when normal URL is entered
                    setUploadedFileName('');
                  }}
                  style={{ 
                    width: '100%', 
                    padding: '8px',
                    border: '1px solid #e0e0e0',
                    borderRadius: '4px',
                    marginTop: '8px'
                  }}
                  readOnly={!!videoDataUrl}
                />
              </Box>
              <Box className={classes.videoSection}>
                {(videoUrl || videoDataUrl) ? (
                  <video
                    controls
                    className={classes.video}
                    src={videoDataUrl || videoUrl}
                  >
                    Your browser does not support the video tag.
                  </video>
                ) : (
                  <Typography color="textSecondary">
                    Please enter a video URL or upload a video file
                  </Typography>
                )}
              </Box>
            </Paper>
          </Grid>

          {/* Right Grid - Script Section */}
          <Grid item xs={12} md={6}>
            <Paper style={{ minHeight: '300px', height: '750px' }}>
            <Box display="flex" justifyContent="space-between" alignItems="center" p={2}>
              <Typography variant="h5">Script</Typography>
              <Box display="flex" alignItems="center" gap={1}>
                <FormControl variant="outlined" size="small" style={{ width: 120 }}>
                  <Select
                    value={selectedLanguage}
                    onChange={(e) => setSelectedLanguage(e.target.value)}
                  >
                    <MenuItem value="Chinese">Chinese</MenuItem>
                    <MenuItem value="English">English</MenuItem>
                    <MenuItem value="Malay">Malay</MenuItem>
                  </Select>
                </FormControl>
                {script && (
                  <IconButton
                    onClick={() => handleCopyContent(script)}
                    size="small"
                    title="Copy content"
                  >
                    <FileCopyIcon fontSize="small" />
                  </IconButton>
                )}
              </Box>
            </Box>
              <Divider />
              <Box 
                p={3}
                style={{
                  height: 'calc(100% - 70px)',
                  overflowY: 'auto'
                }}
              >
                {script ? (
                  <TextEditor
                    script={editorContent}
                    isEditMode={isEditMode}
                    onContentChange={handleContentChange}
                  />
                ) : (
                  <Typography color="textSecondary">
                    No script available. Click transcribe to generate.
                  </Typography>
                )}
              </Box>
            </Paper>
          </Grid>
        </Grid>

        <Box display="flex" justifyContent="flex-end" mt={3} gap={1}>
          <Button
            variant="contained"
            onClick={handleTranscribe}
            disabled={(!videoUrl && !videoDataUrl) || isTranscribing}
            startIcon={isTranscribing ? <CircularProgress size={20} /> : null}
            color="primary"
            style={{ 
              marginRight: 8,
            }}
          >
            {isTranscribing ? 'Transcribing...' : 'Transcribe'}
          </Button>
          <Button
            variant="contained"
            onClick={handleGenerateMoreIdeas}
            disabled={!script || isGeneratingIdeas}
            startIcon={isGeneratingIdeas ? <CircularProgress size={20} /> : <AutorenewIcon />}
            color="primary"
            style={{ marginRight: 8 }}
          >
            {isGeneratingIdeas ? 'Generating...' : 'More Ideas'}
          </Button>
          <Button
            variant="contained"
            onClick={handleRegenerateScript}
            disabled={isRegenerating || !selectedProfile || !script}
            startIcon={isRegenerating ? <CircularProgress size={20} /> : <AutorenewIcon />}
            className={classes.regenerateButton}
            style={{ 
              marginRight: 8,
            }}
          >
            {isRegenerating ? 'Regenerating...' : 'Regenerate'}
          </Button>
          <Button
            variant="contained"
            onClick={handleDownload}
            disabled={!script}
            startIcon={<GetAppIcon />}
            color="primary"
          >
            Download
          </Button>
        </Box>
      </Container>
    </Section>
  );
}

export default VideoRegenerator;