import React, { useState, useEffect, useRef } from "react";
import Autocomplete from '@material-ui/lab/Autocomplete';
import Box from "@material-ui/core/Box";
import BorderColorOutlinedIcon from '@material-ui/icons/BorderColorOutlined';
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import DeleteIcon from "@material-ui/icons/Delete";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Divider from "@material-ui/core/Divider";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import {
  ClickAwayListener,
  FormControl,
  InputLabel,
  Grow,
  MenuItem,
  MenuList,
  Select,
  Popper,
  RadioGroup,
  Radio,
  FormControlLabel,
  TextField,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Toaster, toast } from "react-hot-toast";
import { useAuth } from "../util/auth";
import { useRefresh } from "../context/RefreshContext";
import SavedReplySection from "./SavedReplySection";
import SavedProfileSection from "./SavedProfileSection";
import { saveInputs, retrieveInputs, resetInput, legacySavedOptionsMethod, retrieveProfiles, loadProfileData } from "../util/db";
import {
  LegacyModeSwitch,
  TruncateSelect,
  TruncateAutocomplete,
} from "./functionalComponent/LegacyModeSwitch";
import { useTranslation } from "react-i18next";
import LanguageOutput from "./functionalComponent/LanguageOutput";

const useStyles = makeStyles((theme) => ({
  paperItems: {
    minHeight: "300px",
    height: "950px",
  },
  featured: {
    backgroundColor:
      theme.palette.type === "dark" ? theme.palette.action.selected : "#fdf8c2",
  },
  starFeatured: {
    color: theme.palette.warning.main,
  },
  truncate: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: "500px",
  },
}));

function AttnDemItems(props) {
  const { handleSubmit } = props;
  const {
    onProductNameChange,
    onProductUSPChange,
    onProductContentChange,
    onToneChange,
    attentionModel,
    onAttentionChange,
  } = props;

  const classes = useStyles();
  const auth = useAuth();
  const { refresh } = useRefresh();
  const { t, i18n } = useTranslation();
  // default input declaration
  const [productName, setProductName] = useState([]);
  const [productUSP, setProductUSP] = useState([]);
  const [productContent, setProductContent] = useState([]);
  const [industry, setIndustry] = useState(props.industry);
  // default input legacy declaration
  const [legacyMode, setLegacyMode] = useState(false);
  const [productNamelegacy, setProductNamelegacy] = useState([]);
  const [productUSPlegacy, setProductUSPlegacy] = useState([]);
  const [productContentlegacy, setProductContentlegacy] = useState([]);
  const [savedInputs, setSavedInputs] = useState([]);
  const [nameOptions, setNameOptions] = useState([]);
  const [uspOptions, setUSPOptions] = useState([]);
  const [contentOptions, setContentOptions] = useState([]);
  // savedInputs dialog
  const [openDialog, setOpenDialog] = useState(false);
  // savedProfile dialog
  const [openProfileDialog, setOpenProfileDialog] = useState(false);
  // multiple languages support
  const [selectedLanguage, setSelectedLanguage] = useState("Chinese");

  const [profiles, setProfiles] = useState([]);
  const [selectedProfile, setSelectedProfile] = useState("");

  // button group declaration
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const demandOptions = [
    "故事型 Storytelling (0.4)",
    "情景描述型 Scenario (0.4)",
    "情感型 Feeling (0.4)",
  ];
  const ids = ["ad-2-s", "ad-2-d", "ad-2-f"];

  // Handles the clearing of inputs
  const clearInputs = () => {
    setProductName([]);
    setProductUSP([]);
    setProductContent([]);
  };

  // Handles the legacy mode change
  const handleLegacyModeChange = (newValue) => {
    setLegacyMode(newValue);
  };

  // Handles the dialog toggle for savedInputs
  const handleDialogToggle = () => {
    setOpenDialog(!openDialog);
  };

  // Handles the dialog toggle for savedProfile
  const handleProfileDialogToggle = () => {
    setOpenProfileDialog(!openProfileDialog);
  };

  // Fetch profiles on component mount
  useEffect(() => {
    const fetchProfiles = async () => {
      const fetchedProfiles = await retrieveProfiles(auth.user.uid);
      // Map the fetched profiles to the expected format
      const formattedProfiles = fetchedProfiles.map(profile => ({
        id: profile.value,
        name: profile.label
      }));
      setProfiles(formattedProfiles);
    };
    fetchProfiles();
  }, [auth.user.uid, openProfileDialog]);

  // Load profile data when a profile is selected
  const handleProfileChange = async (event) => {
    const profileId = event.target.value;
    setSelectedProfile(profileId);
    const profileData = await loadProfileData(auth.user.uid, profileId);
    console.log(profileData);
    if (profileData) {
      const productName = profileData.productName || "";
      const productUSP = profileData.productUSP || "";
      const productContent = profileData.productContent || "";

      setProductNamelegacy(productName);
      setProductUSPlegacy(productUSP);
      setProductContentlegacy(productContent);

      onProductNameChange(productName);
      onProductUSPChange(productUSP);
      onProductContentChange(productContent);
    } else {
      setProductNamelegacy("");
      setProductUSPlegacy("");
      setProductContentlegacy("");

      onProductNameChange("");
      onProductUSPChange("");
      onProductContentChange("");
    }
  };

  // Handles the handleSubmit options toggle
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  // Handles the handleSubmit options to close
  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  // Handles the handleSubmit id for different languages
  const handleMenuItemClick = async (event, index) => {
    let id = ids[index];
    if (selectedLanguage === "Chinese") {
      id += "-cn";
    } else if (selectedLanguage === "English") {
      id += "-en";
    } else if (selectedLanguage === "Malay") {
      id += "-malay";
    }
    await handleSubmit(id, event);
    legacyMode && handleSaveInputs();
    setOpen(false);
  };

  // Save inputs from the text fields (Only in Legacy Mode)
  const handleSaveInputs = () => {
    const inputsArray = [
      { key: "product_name", value: productNamelegacy },
      { key: "usp", value: productUSPlegacy },
      { key: "content_angle", value: productContentlegacy },
    ];
    // Check if any of the values are undefined
    const hasUndefinedValues = inputsArray.some(
      (input) => input.value === undefined
    );
    if (hasUndefinedValues) {
      console.error("Some input values are undefined");
      return;
    }
    // Filter out inputs that already exist in the retrieved inputs
    const uniqueInputs = inputsArray.filter(
      (newInput) =>
        !savedInputs.some(
          (savedInput) =>
            savedInput.key === newInput.key &&
            savedInput.value === newInput.value
        )
    );
    // If all inputs are duplicates, stop execution
    if (uniqueInputs.length === 0) {
      console.log("No new inputs to save");
      return;
    }
    // Execute savedInputs
    saveInputs(auth.user.uid, uniqueInputs).then((savedKeys) => {
      handleRetrieveInputs(); // Retrieve the inputs again after saving
      if (savedKeys == undefined) {
        toast.success("Input has been saved");
      }
    });
  };

  // Delete inputs from the dropdown (Only in Legacy Mode)
  const handleDeleteInput = (inputId, inputValue) => {
    resetInput(auth.user.uid, inputId, inputValue)
      .then(() => {
        handleRetrieveInputs();
      })
      .catch((error) => {
        console.error("Error resetting input:", error);
      });
  };

  // Handles the retrieval of savedInputs
  const handleRetrieveInputs = async () => {
    try {
      const inputs = await retrieveInputs(auth.user.uid);
      const formattedInputs = inputs.map((input, index) => ({
        ...input,
      }));
      setSavedInputs(formattedInputs);
    } catch (error) {
      console.error("Error retrieving inputs:", error);
    }
  };

  const fetchLegacyMode = async () => {
    const legacyModeValue = await legacySavedOptionsMethod(auth.user.uid);
    setLegacyMode(legacyModeValue);
    props.onLegacyModeChange(legacyModeValue);
  };

  // useEffect for retrieve legacy mode status and set savedInputs
  useEffect(async () => {
    fetchLegacyMode();
    handleRetrieveInputs();
  }, []);

  // useEffect for retrieve and set savedInputs after refresh
  useEffect(() => {
    handleRetrieveInputs();
  }, [refresh]);

  // useEffect to set savedInputs into options
  useEffect(() => {
    setNameOptions(
      savedInputs
        .filter((input) => input.key === "product_name")
        .map((input) => {
          const valuesArray = Object.values(input.obj).sort();
          return valuesArray.map((value, index) => ({
            value: value,
          }));
        })
        .flat()
        .filter(option => option.value)
    );

    setUSPOptions(
      savedInputs
        .filter((input) => input.key === "usp")
        .map((input) => {
          const valuesArray = Object.values(input.obj).sort();
          return valuesArray.map((value, index) => ({
            value: value,
          }));
        })
        .flat()
        .filter(option => option.value)
    );

    setContentOptions(
      savedInputs
        .filter((input) => input.key === "content_angle")
        .map((input) => {
          const valuesArray = Object.values(input.obj).sort();
          return valuesArray.map((value, index) => ({
            value: value,
          }));
        })
        .flat()
        .filter(option => option.value)
    );
  }, [savedInputs]);

  // Only when legacy mode is false
  const onNameSelectChange = (value) => {
    setProductName(value);
    const nameValues = Array.isArray(value)
      ? value.map((item) => item.value).join(" ")
      : value;
    onProductNameChange(nameValues);
  };
  
  const onUSPSelectChange = (value) => {
    setProductUSP(value);
    const uspValues = Array.isArray(value)
      ? value.map((item) => item.value).join(" ")
      : value;
    onProductUSPChange(uspValues);
  };
  
  const onContentSelectChange = (value) => {
    setProductContent(value);
    const contentValues = Array.isArray(value)
      ? value.map((item) => item.value).join(" ")
      : value;
    onProductContentChange(contentValues);
  };

  return (
    <>
      <Paper className={classes.paperItems}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          padding={2}
        >
          <Toaster position="bottom-right" reverseOrder={false} />
          <Typography variant="h5">Generator</Typography>
          <Box>
            {!legacyMode ? (
              <>
                <Tooltip title="Clear Inputs">
                  <IconButton
                    size="small"
                    color="primary"
                    onClick={clearInputs}
                    style={{ marginLeft: "1rem" }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Edit">
                  <IconButton
                    size="small"
                    color="primary"
                    onClick={handleDialogToggle}
                    style={{ marginLeft: "1rem" }}
                  >
                    <EditOutlinedIcon />
                  </IconButton>
                </Tooltip>
              </>
            ) : (
              <>
                <FormControl style={{ minWidth: 200, marginRight: "1rem" }}>
                  <Select
                    labelId="profile-select-label"
                    id="profile-select"
                    value={selectedProfile}
                    onChange={handleProfileChange}
                    displayEmpty
                  >
                    <MenuItem value="">
                      <em>Select Profile</em>
                    </MenuItem>
                    {profiles.map((profile) => (
                      <MenuItem key={profile.id} value={profile.id}>
                        {profile.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <Tooltip title="Edit">
                  <IconButton
                    size="small"
                    color="primary"
                    onClick={handleProfileDialogToggle}
                    style={{ marginLeft: "1rem" }}
                  >
                    <BorderColorOutlinedIcon />
                  </IconButton>
                </Tooltip>
              </>
            )}

            <LegacyModeSwitch
              auth={auth}
              initialLegacyMode={legacyMode}
              onLegacyModeChange={handleLegacyModeChange}
            />
          </Box>
        </Box>
        <Divider />

        <Box display="flex" flexDirection="column" py={2} px={3}>
          <Typography component="span">
            {/* Step 1: 品牌名字和卖的产品/服务 */}
            Step 1: {t("CPW006")}
          </Typography>
          {legacyMode ? ( // Render AutoComplete when legacyMode is true
            <Autocomplete
              freeSolo
              options={nameOptions.map((option) => option.value)}
              value={productNamelegacy}
              onChange={(event, newValue) => {
                setProductNamelegacy(newValue);
                onNameSelectChange(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  variant="outlined"
                  placeholder={t("CPW001") + t("CPW006")}
                  multiline
                  minRows={2}
                  maxRows={2}
                />
              )}
            />
          ) : (
            // Render Select when legacyMode is false
            <TruncateSelect
              options={nameOptions}
              value={productName}
              onChange={onNameSelectChange}
              // label="Product Name"
              label={t("CPW006")}
              id="product_name"
            />
          )}
        </Box>

        <Box display="flex" flexDirection="column" py={2} px={3}>
          <Typography component="span">
            {/* Step 2: 目标年龄群 */}
            Step 2: {t("CPW007")}
          </Typography>
          <FormControl>
            <InputLabel
              id="select-label-industry"
              margin="dense"
              disableAnimation={true}
            >
              {/* 选择年龄群 */}
              {t("CPW008")}
            </InputLabel>
            <Select
              labelId="select-label-industry"
              id="select-industry"
              // label="age"
              label={t("CPW007")}
              onChange={(e) => setIndustry(e.target.value)}
            >
              {/* <MenuItem value={"general"}>通用</MenuItem> */}
              <MenuItem value={"general"}>{t("CPW009")}</MenuItem>
              <MenuItem value={"20-30"}>20-30</MenuItem>
              <MenuItem value={"30-40"}>30-40</MenuItem>
              <MenuItem value={"40-50"}>40-50</MenuItem>
              <MenuItem value={"50-60"}>50-60</MenuItem>
            </Select>
          </FormControl>
        </Box>

        <Box display="flex" flexDirection="column" py={2} px={3}>
          <Typography component="span">
            {/* Step 3: 口吻 */}
            Step 3: {t("CPW034")}
          </Typography>
          <FormControl>
            <InputLabel
              id="select-label-tone"
              margin="dense"
              disableAnimation={true}
            >
              {/* 选择口吻 */}
              {t("CPW002") + t("CPW034")}
            </InputLabel>
            <Select
              labelId="select-label-tone"
              id="select-tone"
              label="tone"
              value={props.tone}
              onChange={onToneChange}
            >
              {/* <MenuItem value={"general"}>通用</MenuItem> */}
              <MenuItem value={"general"}>{t("CPW009")}</MenuItem>
              {/* <MenuItem value={"幽默"}>幽默</MenuItem> */}
              <MenuItem value={"幽默"}>{t("CPW010")}</MenuItem>
              {/* <MenuItem value={"认真"}>认真</MenuItem> */}
              <MenuItem value={"认真"}>{t("CPW011")}</MenuItem>
              {/* <MenuItem value={"说服力"}>说服力</MenuItem> */}
              <MenuItem value={"说服力"}>{t("CPW012")}</MenuItem>
              {/* <MenuItem value={"专业"}>专业</MenuItem> */}
              <MenuItem value={"专业"}>{t("CPW013")}</MenuItem>
              {/* <MenuItem value={"挑战认知"}>逆向思维</MenuItem> */}
              <MenuItem value={"挑战认知"}>{t("CPW014")}</MenuItem>
            </Select>
          </FormControl>
        </Box>

        <Box display="flex" flexDirection="column" py={2} px={3}>
          <Typography component="span">
            {/* Step 4: 品牌卖点 (USP) */}
            Step 4: {t("CPW015")}
          </Typography>
          {legacyMode ? ( // Render AutoComplete when legacyMode is true
            <Autocomplete
              freeSolo
              options={uspOptions.map((option) => option.value)}
              value={productUSPlegacy}
              onChange={(event, newValue) => {
                setProductUSPlegacy(newValue);
                onUSPSelectChange(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  variant="outlined"
                  placeholder={t("CPW001") + t("CPW015")}
                  multiline
                  minRows={2}
                  maxRows={2}
                />
              )}
            />
          ) : (
            // Render Select when legacyMode is false
            <TruncateSelect
              options={uspOptions}
              value={productUSP}
              onChange={onUSPSelectChange}
              // label="USP"
              label={t("CPW015")}
              id="usp"
            />
          )}
        </Box>

        <Box display="flex" flexDirection="column" py={2} px={3}>
          <Typography component="span">
            {/* Step 5: 模仿风格, 加入1到3个你觉得很Work的Angle风格 */}
            Step 5: {t("CPW016")}
          </Typography>
          {legacyMode ? ( // Render AutoComplete when legacyMode is true
            <Autocomplete
              freeSolo
              options={contentOptions.map((option) => option.value)}
              value={productContentlegacy}
              onChange={(event, newValue) => {
                setProductContentlegacy(newValue);
                onContentSelectChange(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  variant="outlined"
                  placeholder={t("CPW001") + t("CPW016")}
                  multiline
                  minRows={2}
                  maxRows={2}
                />
              )}
            />
          ) : (
            // Render Select when legacyMode is false
            <TruncateSelect
              options={contentOptions}
              value={productContent}
              onChange={onContentSelectChange}
              label="Content Angle"
              id="angle"
            />
          )}
        </Box>

        <Box display="flex" flexDirection="column" py={2} px={3}>
          <Typography component="span">
            {/* Attention Model Selection */}
            Step 6: {t("CPW097")}
          </Typography>
          <FormControl component="fieldset">
            <RadioGroup
              aria-label="model"
              name="model"
              value={attentionModel}
              onChange={onAttentionChange}
            >
              <FormControlLabel
                value="1"
                control={<Radio />}
                label={t("CPW098")}
              />
              <FormControlLabel
                value="2"
                control={<Radio />}
                label={t("CPW099")}
              />
              <FormControlLabel
                value="3"
                control={<Radio />}
                label={"3.0版本"}
              />
            </RadioGroup>
          </FormControl>
        </Box>
        {/* <LanguageOutput selectedLanguage={selectedLanguage} setSelectedLanguage={setSelectedLanguage} /> */}

        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-around"
          py={2}
          px={3}
        >
          <Button
            variant="contained"
            size="large"
            color="primary"
            type="submit"
            onClick={async (e) => {
              let adId;
              if (selectedLanguage === "Chinese") {
                attentionModel === 1 ? adId = "ad-1-cn" : adId = "ad-1-cn-2";
              } else if (selectedLanguage === "English") {
                attentionModel === 1 ? adId = "ad-1-en" : adId = "ad-1-en-2";
              } else if (selectedLanguage === "Malay") {
                attentionModel === 1 ? adId = "ad-1-malay" : adId = "ad-1-malay-2";
              }
              await handleSubmit(adId, e);
              legacyMode && handleSaveInputs();
            }}
          >
            Attention (0.4)
          </Button>

          <ButtonGroup
            variant="contained"
            color="primary"
            ref={anchorRef}
            aria-label="split button"
          >
            <Button onClick={handleToggle}>Demand (0.4)</Button>
            <Button
              color="primary"
              size="small"
              aria-controls={open ? "split-button-menu" : undefined}
              aria-expanded={open ? "true" : undefined}
              aria-label="select option"
              aria-haspopup="menu"
              onClick={handleToggle}
            >
              ▼
            </Button>
          </ButtonGroup>
          <Popper
            open={open}
            anchorEl={anchorRef.current}
            transition
            disablePortal
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin:
                    placement === "bottom" ? "center top" : "center bottom",
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList id="split-button-menu">
                      {demandOptions.map((option, index) => (
                        <MenuItem
                          key={option}
                          onClick={(event) => handleMenuItemClick(event, index)}
                        >
                          {option}
                        </MenuItem>
                      ))}
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </Box>
      </Paper>

      {/* Dialog for users to edit saved replies */}
      <Dialog
        open={openDialog}
        onClose={handleDialogToggle}
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle>
          {/* Edit Saved Replies */}
          {t("CPW003")}
        </DialogTitle>
        <DialogContent>
          <SavedReplySection />
        </DialogContent>
      </Dialog>

      {/* Dialog for users to edit saved brand profiles */}
      <Dialog
        open={openProfileDialog}
        onClose={handleProfileDialogToggle}
        fullWidth
        maxWidth="lg"
      >
        <DialogContent>
          <SavedProfileSection />
        </DialogContent>
      </Dialog>
    </>
  );
}

export default AttnDemItems;
