import React from "react";
import Meta from "../../components/Meta";
import { requireProUser } from "../../util/auth";
import { ReplyRegenerator } from "../../components/regenerator/ReplyRegenerator";

function ReplyRegeneratorPage(props) {
  return (
    <>
      <Meta title="Reply Regenerator" />
      <ReplyRegenerator />
    </>
  );
}

export default requireProUser(ReplyRegeneratorPage);
