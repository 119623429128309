import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  TextField,
  Button,
  Box,
  FormLabel,
  Card,
  Tabs,
  Tab,
} from "@material-ui/core";
import { CircularProgress } from "@material-ui/core";
import GetAppIcon from '@material-ui/icons/GetApp';
import { toast } from "react-hot-toast";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    width: "15%",
    minWidth: 120,
  },
  searchControl: {
    margin: theme.spacing(1),
    width: "50%",
    minWidth: 360,
  },
  searchButton: {
    backgroundColor: "#3b5998",
    color: "white",
    "&:hover": {
      backgroundColor: "blue",
    },
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    height:"20%",
    // marginTop: "1rem",
    marginBottom: "1rem",
    padding: theme.spacing(2),
    minWidth: 100,
    borderRadius: 100,
    // fontSize: '1rem',
  },
  container: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: "1rem",
  },
  searchBarLabel: {
    marginBottom: "0.5rem",
  },
  selectInput: {
    height: 40, 
    '& .MuiSelect-select': {
      paddingTop: 4,
      paddingBottom: 4,
    },
    '& .MuiOutlinedInput-input': {
      padding: '10px 14px',
    }
  },
}));

const AdLibraryFilter = ({
  onSearchClick,
  selectedCards,
  saveMedia,
  onFilterChange,
  onFilterResultsChange,
  clearData,
  onClearSelection,
  mediaType,
  language,
  searchQuery,
  onMediaChange,
  onLanguageChange,
  onSearchQueryChange,
  onDownloadMedia,
  isLoading,
  searchType,
  onSpecificAdScrape,
  onSearchTypeChange,
}) => {
  const classes = useStyles();
  const [urlInput, setUrlInput] = useState("");

  const handleUrlSubmit = async () => {
    console.log("submitting url", urlInput);
    // URL validation
    if (!urlInput.includes("facebook.com/ads/library")) {
      toast.error("Please enter a valid Facebook Ad Library URL");
      return;
    }
    console.log("url is valid");
    try {
      await onSpecificAdScrape(urlInput);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Box style={{ width: "100%" }}>
      <Box className={classes.container}>

        {/* Country */}
        <FormControl className={classes.formControl} disabled>
          <FormLabel
            component="legend"
            id="country-label"
            className={classes.searchBarLabel}
          >
            Country
          </FormLabel>
          <Select
            className={classes.selectInput}
            labelid="country-label"
            id="country-select"
            key="country-select"
            value="Malaysia"
            variant="outlined"
          >
            <MenuItem value="Malaysia">Malaysia</MenuItem>
          </Select>
        </FormControl>

        {/* Active Ads */}
        <FormControl className={classes.formControl} disabled>
          <FormLabel
            component="legend"
            id="category-label"
            className={classes.searchBarLabel}
          >
            Ad Type
          </FormLabel>
          <Select
            className={classes.selectInput}
            labelid="category-label"
            id="category-select"
            key="category-select"
            value="Active ads"
            variant="outlined"
          >
            <MenuItem value="Active ads">Active ads</MenuItem>
          </Select>
        </FormControl>

        {/* Language */}
        <FormControl className={classes.formControl}>
          <FormLabel
            component="legend"
            id="language-label"
            className={classes.searchBarLabel}
          >
            Language
          </FormLabel>
          <Select
            className={classes.selectInput}
            labelid="language-label"
            id="language-select"
            key="language-select"
            value={language  || "english"}
            onChange={onLanguageChange}
            variant="outlined"
          >
            <MenuItem value="chinese">Chinese</MenuItem>
            <MenuItem value="english">English</MenuItem>
            <MenuItem value="malay">Malay</MenuItem>
          </Select>
        </FormControl>

        {/* Media Type */}
        <FormControl className={classes.formControl}>
          <FormLabel
            component="legend"
            id="media-label"
            className={classes.searchBarLabel}
          >
            Media Type
          </FormLabel>
          <Select
            className={classes.selectInput}
            labelid="media-label"
            id="media-select"
            key="media-select"
            value={mediaType}
            onChange={onMediaChange}
            variant="outlined"
          >
            <MenuItem value="all">All</MenuItem>
            <MenuItem value="imageText">Image</MenuItem>
            <MenuItem value="video">Video</MenuItem>
          </Select>
        </FormControl>
      </Box>

      <Box className={classes.searchContainer}>
        <Box className={classes.tabsContainer}>
          <Tabs
            value={searchType}
            onChange={(_, newValue) => onSearchTypeChange(newValue)}
            indicatorColor="primary"
            textColor="primary"
          >
            <Tab label="Search by Keyword" value="keyword" />
            <Tab label="Search by URL" value="url" />
          </Tabs>
        </Box>

        <Box className={classes.searchInputGroup}>
          {searchType === "keyword" ? (
            <>
              <FormControl className={classes.searchControl}>
                <TextField
                  className={classes.selectInput}
                  variant="outlined"
                  value={searchQuery}
                  onChange={onSearchQueryChange}
                  placeholder="Enter keywords to search..."
                  fullWidth
                />
              </FormControl>
              <Button
                onClick={onSearchClick}
                disabled={isLoading || searchQuery === ""}
                variant="contained"
                className={classes.searchButton}
              >
                {isLoading ? (
                  <CircularProgress size={24} color="secondary" />
                ) : (
                  "Search (0.5)"
                )}
              </Button>
            </>
          ) : (
            <>
              <FormControl className={classes.searchControl}>
                <TextField
                  className={classes.selectInput}
                  variant="outlined"
                  value={urlInput}
                  onChange={(e) => setUrlInput(e.target.value)}
                  placeholder="https://www.facebook.com/ads/library/?id=123456789"
                  fullWidth
                />
              </FormControl>
              <Button
                onClick={handleUrlSubmit}
                disabled={isLoading || !urlInput}
                variant="contained"
                className={classes.searchButton}
              >
                {isLoading ? (
                  <CircularProgress size={24} color="secondary" />
                ) : (
                  "Import Ad (0.3)"
                )}
              </Button>
            </>
          )}
        </Box>
      </Box>

      {/* Move selected cards actions to a separate row if needed */}
      {selectedCards.length > 0 && (
        <Box display="flex" gap={1} mt={2}>
          <Button
            variant="contained"
            className={classes.actionButton}
            onClick={saveMedia}
          >
            Save Media
          </Button>
          <Button
            variant="contained"
            className={classes.actionButton}
            onClick={onDownloadMedia}
          >
            <GetAppIcon />
          </Button>
          <Button
            variant="contained"
            className={classes.actionButton}
            onClick={onClearSelection}
          >
            Clear
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default AdLibraryFilter;
