import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Typography,
  CircularProgress,
  Grid,
  IconButton,
  Container,
  Divider,
  makeStyles,
  Paper,
  FormControl,
  Select,
  MenuItem,
} from '@material-ui/core';
import ReactHtmlParser from '@orrisroot/react-html-parser';
import CreatableSelect from 'react-select/creatable';
import { saveAs } from "file-saver";
import { useAuth } from "../../util/auth";
import { Toaster, toast } from "react-hot-toast";
import AutorenewIcon from '@material-ui/icons/Autorenew';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import GetAppIcon from '@material-ui/icons/GetApp';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import { retrieveProfiles, loadProfileData } from '../../util/db.js';
import { fetchDataWithoutTyping } from "../functionalComponent/fetchData";
import Section from "../Section";
import SectionHeader from "../SectionHeader";
import { TextEditor } from "../richtexteditor/TextEditor";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
    height: '100%',
  },
  profileSelect: {
    marginBottom: theme.spacing(3),
  },
  gridContainer: {
    flexGrow: 1,
    height: 'calc(100% - 200px)',
  },
  scriptSection: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  scriptContent: {
    flex: 1,
    padding: theme.spacing(2),
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    overflowY: 'auto',
    backgroundColor: theme.palette.background.paper,
    '& p': {
      marginBottom: theme.spacing(1),
    },
  },
  regenerateButton: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.success.dark,
    },
    '&:disabled': {
      backgroundColor: theme.palette.action.disabledBackground,
      color: theme.palette.action.disabled,
    }
  },
}));

const MemoizedSelect = React.memo(({ value, onChange, options, ...props }) => (
  <CreatableSelect
    value={value}
    onChange={onChange}
    options={options}
    {...props}
  />
));

export function UspRegenerator() {
  const classes = useStyles();
  const auth = useAuth();
  const [selectedProfile, setSelectedProfile] = useState(null);
  const [originalUSP, setOriginalUSP] = useState('');
  const [regeneratedUSP, setRegeneratedUSP] = useState('');
  const [isRegenerating, setIsRegenerating] = useState(false);
  const [profiles, setProfiles] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState("Chinese");
  const [editorContent, setEditorContent] = useState('');
  const [isEditMode, setIsEditMode] = useState(false);
  const [isGeneratingIdeas, setIsGeneratingIdeas] = useState(false);

  useEffect(() => {
    if (auth.user) {
      retrieveProfiles(auth.user.uid).then(setProfiles);
    }
  }, [auth.user]);

  const handleRegenerateUSP = async () => {
    if (!selectedProfile) {
      toast.error('Please select a profile first');
      return;
    }

    setIsRegenerating(true);
    try {
      const profileData = await loadProfileData(auth.user.uid, selectedProfile.value);
      
      const query = `Original USP with structure to absolutely follow: ${originalUSP}.
      Product Information: Product Name: ${profileData.productName}, 
      Product Ingredients: ${profileData.productIngredients}, 
      Product Content: ${profileData.productContent},
      Product Certification: ${profileData.productCert}
      We only want the modified original USP, nothing else, no additional information.
      `;
      
      let id = selectedLanguage === "Chinese" ? "usp-regenerate-cn" :
               selectedLanguage === "English" ? "usp-regenerate-en" :
               "usp-regenerate-malay";

      const payload = { id, query, credit: 0.3 };

      const newUSP = await toast.promise(
        fetchDataWithoutTyping(payload, auth),
        {
          loading: "Regenerating USP...",
          success: "USP regenerated!",
          error: "Failed to regenerate USP.",
        }
      );

      const formattedUSP = newUSP
      .split('\n')
      .filter(line => line.trim())
      .map(line => `<p>${line}</p>`)
      .join('');
      
      setRegeneratedUSP(prev => prev + formattedUSP);
    } catch (error) {
      console.error('Error regenerating USP:', error);
      toast.error(error.message || 'Failed to regenerate USP');
    } finally {
      setIsRegenerating(false);
    }
  };

  const handleGenerateMoreIdeas = async () => {
    if (!selectedProfile) {
      toast.error('Please select a profile first');
      return;
    }
  
    setIsGeneratingIdeas(true);
    try {
      const profileData = await loadProfileData(auth.user.uid, selectedProfile.value);
      
      const query = `Generate completely different USP angles and ideas for this product.
      Each idea should highlight unique value propositions and benefits.
      
      Product Details:
      - Name: ${profileData.productName}
      - Ingredients: ${profileData.productIngredients}
      - Key Features: ${profileData.productContent}
      - Certifications: ${profileData.productCert}
  
      Previous USPs to avoid repeating (generate completely different angles):
      ${regeneratedUSP}
  
      Requirements:
      1. Each USP should focus on a distinct product benefit
      2. Include compelling value propositions
      3. Highlight different competitive advantages
      4. Use varied presentation styles
      5. Format each USP with clear structure`;
  
      let id = selectedLanguage === "Chinese" ? "usp-idea-generator-cn" :
               selectedLanguage === "English" ? "usp-idea-generator-en" :
               "usp-idea-generator-malay";

      const payload = { id, query, credit: 0.5 };
  
      const newIdeas = await toast.promise(
        fetchDataWithoutTyping(payload, auth),
        {
          loading: "Generating new USP ideas...",
          success: "New USP ideas generated!",
          error: "Failed to generate ideas.",
        }
      );
  
      const formattedIdeas = newIdeas
        .split('\n')
        .filter(line => line.trim())
        .map(line => `<p>${line}</p>`)
        .join('');

      const separator = `
        <div style="text-align: center; font-weight: bold; margin: 20px 0;">
          ============== More USP Ideas ==============
        </div>
      `;
      
      setRegeneratedUSP(prev => prev + separator + formattedIdeas);
      
    } catch (error) {
      console.error('Error generating USP ideas:', error);
      toast.error(error.message || 'Failed to generate ideas');
    } finally {
      setIsGeneratingIdeas(false);
    }
  };

  const handleCopyContent = async (content) => {
    try {
      // Format content similar to how we generate it
      const formattedContent = content
        .replace(/<p>/g, '') // Remove opening p tags
        .replace(/<\/p>/g, '\n') // Convert closing p tags to newlines
        .replace(/<div[^>]*>/g, '\n') // Convert div openings to newlines
        .replace(/<\/div>/g, '\n') // Convert div closings to newlines
        .replace(/<br\s*\/?>/g, '\n') // Convert br tags to newlines
        .replace(/&nbsp;/g, ' ') // Convert &nbsp; to spaces
        .replace(/<[^>]*>/g, '') // Remove any remaining HTML tags
        .replace(/\n\s*\n/g, '\n\n') // Remove excessive blank lines
        .replace(/^\s+|\s+$/g, '') // Trim whitespace
        .replace(/•/g, '  •') // Add spacing before bullets
        .replace(/=+/g, '='); // Preserve separator lines
  
      // Create blob with both HTML and plain text versions
      const htmlContent = `
        <html>
          <body>
            <pre style="font-family: Arial, sans-serif; white-space: pre-wrap;">${formattedContent}</pre>
          </body>
        </html>
      `;
  
      // Create ClipboardItem with both formats
      const clipboardItem = new ClipboardItem({
        'text/html': new Blob([htmlContent], { type: 'text/html' }),
        'text/plain': new Blob([formattedContent], { type: 'text/plain' })
      });
  
      await navigator.clipboard.write([clipboardItem]);
      toast.success('Content copied to clipboard!');
    } catch (err) {
      console.error('Failed to copy:', err);
      // Fallback to basic text copying
      try {
        // Use the same formatting for fallback
        const plainText = content
          .replace(/<p>/g, '')
          .replace(/<\/p>/g, '\n')
          .replace(/<div[^>]*>/g, '\n')
          .replace(/<\/div>/g, '\n')
          .replace(/<br\s*\/?>/g, '\n')
          .replace(/&nbsp;/g, ' ')
          .replace(/<[^>]*>/g, '')
          .replace(/\n\s*\n/g, '\n\n')
          .replace(/^\s+|\s+$/g, '')
          .replace(/•/g, '  •')
          .replace(/=+/g, '=');
  
        await navigator.clipboard.writeText(plainText);
        toast.success('Content copied to clipboard (plain text)!');
      } catch (fallbackErr) {
        toast.error('Failed to copy content');
      }
    }
  };

  const downloadUSP = () => {
    if (!regeneratedUSP) {
      toast.error('No regenerated USP to download');
      return;
    }
    const blob = new Blob([regeneratedUSP], { type: 'text/plain' });
    saveAs(blob, `usp_${Date.now()}.txt`);
    toast.success('USP downloaded successfully!');
  };

  return (
    <Section>
      <Container>
        <SectionHeader
          title="USP Regenerator"
          subtitle="Regenerate your Unique Selling Propositions with AI"
          size={4}
          textAlign="center"
        />
        <Toaster position="bottom-right" reverseOrder={false} />

        <Box mb={3}>
          <MemoizedSelect
            isClearable
            placeholder="Select profile..."
            options={profiles}
            value={selectedProfile}
            onChange={setSelectedProfile}
          />
        </Box>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <Paper style={{ minHeight: '300px', height: '650px' }}>
              <Box display="flex" justifyContent="space-between" alignItems="center" p={2}>
                <Typography variant="h5">Original USP</Typography>
              </Box>
              <Divider />
              <Box 
                p={3} 
                style={{ 
                  height: 'calc(100% - 70px)', 
                  overflowY: 'auto',
                  cursor: 'text',
                }}
                onClick={(e) => {
                  const editorElement = e.currentTarget.querySelector('[contenteditable="true"]');
                  if (editorElement) {
                    editorElement.focus();
                  }
                }}
              >
                <TextEditor
                  script={originalUSP}
                  isEditMode={true}
                  onContentChange={(content) => setOriginalUSP(content)}
                />
              </Box>
            </Paper>
          </Grid>

          <Grid item xs={12} md={6}>
            <Paper style={{ minHeight: '300px', height: '650px' }}>
              <Box display="flex" justifyContent="space-between" alignItems="center" p={2}>
                <Typography variant="h5">Regenerated USP</Typography>
                <Box display="flex" alignItems="center" gap={1}>
                  <FormControl variant="outlined" size="small" style={{ width: 120 }}>
                    <Select
                      value={selectedLanguage}
                      onChange={(e) => setSelectedLanguage(e.target.value)}
                    >
                      <MenuItem value="Chinese">Chinese</MenuItem>
                      <MenuItem value="English">English</MenuItem>
                      <MenuItem value="Malay">Malay</MenuItem>
                    </Select>
                  </FormControl>
                  <IconButton
                    onClick={() => setIsEditMode(!isEditMode)}
                    size="small"
                    title={isEditMode ? "Save" : "Edit"}
                  >
                    {isEditMode ? <SaveIcon fontSize="small" /> : <EditIcon fontSize="small" />}
                  </IconButton>
                  {editorContent && (
                    <IconButton
                      onClick={() => handleCopyContent(editorContent)}
                      size="small"
                      title="Copy content"
                    >
                      <FileCopyIcon fontSize="small" />
                    </IconButton>
                  )}
                </Box>
              </Box>
              <Divider />
              <Box 
                p={3}
                style={{
                  height: 'calc(100% - 70px)',
                  overflowY: 'auto',
                  cursor: 'text'
                }}
                onClick={(e) => {
                  const editorElement = e.currentTarget.querySelector('[contenteditable="true"]');
                  if (editorElement) {
                    editorElement.focus();
                  }
                }}
              >
                {regeneratedUSP ? (
                  <TextEditor
                    script={regeneratedUSP}
                    isEditMode={isEditMode}
                    onContentChange={(content) => setRegeneratedUSP(content)}
                  />
                ) : (
                  <Typography color="textSecondary">
                    Click regenerate to create new USP
                  </Typography>
                )}
              </Box>
            </Paper>
          </Grid>
        </Grid>

        <Box display="flex" justifyContent="flex-end" mt={3}>
          <Button
            variant="contained"
            onClick={handleGenerateMoreIdeas}
            disabled={!regeneratedUSP || isGeneratingIdeas}
            startIcon={isGeneratingIdeas ? <CircularProgress size={20} /> : <AutorenewIcon />}
            color="primary"
            style={{ marginRight: 8 }}
          >
            {isGeneratingIdeas ? 'Generating...' : 'More Ideas'}
          </Button>
          <Button
            variant="contained"
            onClick={handleRegenerateUSP}
            disabled={isRegenerating || !selectedProfile}
            startIcon={isRegenerating ? <CircularProgress size={20} /> : <AutorenewIcon />}
            color="primary"
            style={{ 
              marginRight: 8,
            }}
          >
            {isRegenerating ? 'Regenerating...' : 'Regenerate'}
          </Button>
          <Button
            variant="contained"
            onClick={downloadUSP}
            disabled={!regeneratedUSP}
            startIcon={<GetAppIcon />}
            color="primary"
          >
            Download
          </Button>
        </Box>
      </Container>
    </Section>
  );
}