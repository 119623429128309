import React, { useState, useEffect, useRef, memo } from "react";
import {
  getFirestore,
  onSnapshot,
  doc,
  getDoc,
  setDoc,
} from "firebase/firestore";
import { firebaseApp } from "../../util/firebase";
import Box from "@material-ui/core/Box";
import {
  Typography,
  Button,
  Card,
  Grid,
  Divider,
  Avatar,
  Tabs,
  Tab,
  FormControl,
  Select,
  MenuItem,
} from "@material-ui/core";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import { useAuth } from "../../util/auth";
import Section from "../Section";
import SectionHeader from "../SectionHeader";
import Modal from "@material-ui/core/Modal";
import CardContent from "@material-ui/core/CardContent";
import Tooltip from "@material-ui/core/Tooltip";
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "@orrisroot/react-html-parser";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import DescriptionTwoToneIcon from "@material-ui/icons/DescriptionTwoTone";
import SaveIcon from "@material-ui/icons/Save";
import GetAppIcon from "@material-ui/icons/GetApp";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import { useTheme } from "@material-ui/core/styles";
// import JSZip from 'jszip';
import { TextEditor } from "../richtexteditor/TextEditor";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { saveAs } from "file-saver";
import { Toaster, toast } from "react-hot-toast";
import copy, { CopyToClipboard } from "react-copy-to-clipboard";
import CircularProgress from '@material-ui/core/CircularProgress';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import CreatableSelect from 'react-select/creatable';
import { retrieveProfiles, loadProfileData } from '../../util/db.js';
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import CardMedia from '@material-ui/core/CardMedia';
import Checkbox from '@material-ui/core/Checkbox';
import AssessmentIcon from '@material-ui/icons/Assessment';
import ContentCopyIcon from '@material-ui/icons/FileCopy';
import { fetchDataWithoutTyping } from "../functionalComponent/fetchData";

const useStyles = makeStyles((theme) => ({
  paper: {
    margin: theme.spacing(3),
    padding: theme.spacing(3),
    boxShadow: theme.shadows[4],
  },
  button: {
    color: "white",
    backgroundColor: "#3f51b5",
    "&:hover": {
      backgroundColor: "#303f9f",
    },
    margin: "10px",
  },
  disabledButton: {
    color: "rgba(0, 0, 0, 0.26)",
    backgroundColor: "rgba(0, 0, 0, 0.12)",
    margin: "10px",
  },
  typography: {
    fontWeight: "bold",
  },
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  // modal: {
  //   display: "flex",
  //   alignItems: "center",
  //   justifyContent: "center",
  // },
  // scriptModalContent: {
  //   backgroundColor: theme.palette.background.paper,
  //   display: "flex",
  //   flexDirection: "column",
  //   alignItems: "center",
  //   justifyContent: "center",
  //   border: "2px solid #000",
  //   borderRadius: "30px",
  //   boxShadow: theme.shadows[5],
  //   padding: theme.spacing(2, 4, 3),
  //   width: "80%",
  //   height: "80vh",
  //   overflow: "auto",
  // },
  modalAdContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  modalContainerButtons: {
    display: "flex",
    width: "80%",
    justifyContent: "space-between",
  },
  modalAdContent: {
    width: "80%",
  },
  squareCard: {
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
    padding: theme.spacing(1),
    margin: theme.spacing(2),
    width: "95%",
    // height: "40rem",
    maxHeight: "95%",
    overflow: "auto",
  },
  cardBox: {
    display: "flex",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  cardActionArea: {
    display: "block",
    textAlign: "inherit",
    width: "100%",
  },
  getAppIconStyle: {
    color: "#5c6bc0",
    // position: "absolute",
    // top: 15,
    // right: 10,
    cursor: "pointer",
    "&:hover": {
      color: "#3f51b5",
    },
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
  },
  modalContent: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
    width: '90%',
    maxWidth: '1400px',
    height: '85vh',
    maxHeight: '85vh', // Add maxHeight
    outline: 'none',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden', // Add overflow hidden
  },
  gridContainer: {
    flex: 1,
    minHeight: 0, // Important for nested flex containers
    overflow: 'hidden', // Add overflow hidden
  },
  videoSection: {
    backgroundColor: '#000',
    borderRadius: theme.shape.borderRadius,
    overflow: 'hidden',
    height: '100%', // Take full height of parent
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  video: {
    maxWidth: '100%',
    maxHeight: '100%',
    objectFit: 'contain',
  },
  scriptSection: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%', // Take full height of parent
    backgroundColor: theme.palette.grey[50],
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(2),
    overflow: 'hidden', // Add overflow hidden
  },
  scriptHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
  scriptContent: {
    flex: 1, // Take remaining space
    overflowY: 'auto',
    backgroundColor: 'white',
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(2),
    minHeight: 0, // Important for nested flex containers
  },
  actionButton: {
    borderRadius: 20,
    padding: theme.spacing(1, 3),
    marginLeft: theme.spacing(1),
    textTransform: 'none',
  },
  scriptActions: {
    display: 'flex',
    gap: theme.spacing(1),
  },
  regenerateButton: {
    backgroundColor: theme.palette.success.main,
    color: 'white',
    '&:hover': {
      backgroundColor: theme.palette.success.dark,
    },
  },
  profileSelect: {
    marginBottom: theme.spacing(2),
    width: '100%',
  },
  imageEditorModal: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
    width: '90%',
    maxWidth: '1200px',
    maxHeight: '90vh',
    overflow: 'auto',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
}));

const ScraperHistorySection = (props) => {
  const classes = useStyles();
  const theme = useTheme();

  const auth = useAuth();

  const [usageData, setUsageData] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [expandedDiv, setExpandedDiv] = useState(null);
  const [selectedCards, setSelectedCards] = useState([]);
  const [filterValue, setFilterValue] = useState(null);
  const [filterResults, setFilterResults] = useState("");
  const [activeTab, setActiveTab] = useState("images");
  const [isEditMode, setIsEditMode] = useState(false);
  const [editorContent, setEditorContent] = useState("");
  const [isTranscribing, setIsTranscribing] = useState(false);

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 15;

  // Calculate the index of the first and last items on the current page
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  // Slice the usageData array to get only the items for the current page
  const currentItems = [...usageData]
  .sort((a, b) => b.startDate - a.startDate) // Sort by startDate descending
  .slice(indexOfFirstItem, indexOfLastItem);
  
  const filteredItems = currentItems.filter((item) =>
    activeTab === "images"
      ? item.images && item.images.length > 0
      : item.videos && item.videos.length > 0
  );

  // Calculate the total number of pages
  const totalPages = Math.ceil(usageData.length / itemsPerPage);

  const [isRegenerating, setIsRegenerating] = useState(false);
  const [selectedProfile, setSelectedProfile] = useState(null);
  const [profiles, setProfiles] = useState([]);
  const [isRegeneratingImage, setIsRegeneratingImage] = useState(false);
  const [selectedImageProfile, setSelectedImageProfile] = useState(null);
  const [isImageEditorOpen, setIsImageEditorOpen] = useState(false);
  const [selectedAdData, setSelectedAdData] = useState(null);
  const [selectedImages, setSelectedImages] = useState([]);

  // copywriting regeneration states
  const [isCopywritingModalOpen, setIsCopywritingModalOpen] = useState(false);
  const [selectedCopywritingData, setSelectedCopywritingData] = useState(null);
  const [regeneratedCopywriting, setRegeneratedCopywriting] = useState('');
  const [isRegeneratingCopy, setIsRegeneratingCopy] = useState(false);
  const [selectedCopyProfile, setSelectedCopyProfile] = useState(null);
  const [selectedLanguage, setSelectedLanguage] = useState("Chinese");

  // Add this useEffect to fetch profiles
  useEffect(() => {
    if (auth.user) {
      retrieveProfiles(auth.user.uid).then(setProfiles);
    }
  }, [auth.user]);

  const handleRegenerateScript = async () => {
    if (!selectedProfile) {
      toast.error('Please select a profile first');
      return;
    }
  
    setIsRegenerating(true);
    try {
      const profileData = await loadProfileData(auth.user.uid, selectedProfile.value);
      
      // Prepare regeneration data
      const regenerationData = {
        videoId: selectedData.adArchiveID,
        profileData: profileData,
        originalScript: selectedData.script
      };
      
      const query = `Reference Script: ${selectedData.script}. 
      Product Information: Product Name: ${profileData.productName}, 
      Product USP: ${profileData.productUSP}, 
      Product Ingredients: ${profileData.productIngredients}, 
      Product Content: ${profileData.productContent},
      Product Certification: ${profileData.productCert}`;
      const id = "script-regenerate"
      let credit = 0.5
      let payload = { id, query, credit }

      // Make the API call with toast promise
      const newScript = await toast.promise(fetchDataWithoutTyping(payload, auth), {
        loading: "Regenerating script...",
        success: "Script regenerated!",
        error: "Failed to regenerate script.",
      });
  
      // Append new script to existing content
      const separator = '\n\n==============\n      New Version      \n==============\n\n';
      const updatedScript = `${selectedData.script}${separator}${newScript}`;
      
      setEditorContent(updatedScript);
  
      // Update Firestore and local state using existing function
      await saveEditedScript();
  
      // Update local state for display
      setSelectedData({
        ...selectedData,
        script: updatedScript
      });
  
    } catch (error) {
      console.error('Error regenerating script:', error);
      toast.error(error.message || 'Failed to regenerate script');
    } finally {
      setIsRegenerating(false);
    }
  };

  const downloadScript = () => {
    if (!selectedData?.script) {
      toast.error('No script available to download');
      return;
    }
  
    // Create a blob with the script content, replacing <br> tags with newlines
    const scriptContent = selectedData.script.replace(/<br>/g, "\n");
    const blob = new Blob([scriptContent], { type: 'text/plain' });
    // Create a download link and trigger it
    saveAs(blob, `script_${selectedData.adArchiveID}.txt`);
    toast.success('Script downloaded successfully!');
  };
  
  const handleOpenImageEditor = (data) => {
    setSelectedAdData(data);
    setSelectedImages([]);
    setIsImageEditorOpen(true);
  };
  
  const handleCloseImageEditor = () => {
    setIsImageEditorOpen(false);
    setSelectedAdData(null);
    setSelectedImages([]);
    setSelectedImageProfile(null);
  };
  
  const handleImageSelection = (index) => {
    setSelectedImages(prev => 
      prev.includes(index)
        ? prev.filter(i => i !== index)
        : [...prev, index]
    );
  };
  
  const handleRegenerateSelectedImages = async (adArchiveID) => {
    if (!selectedImageProfile || selectedImages.length === 0) {
      toast.error('Please select a profile and at least one image');
      return;
    }
  
    setIsRegeneratingImage(true);
    try {
      const profileData = await loadProfileData(auth.user.uid, selectedImageProfile.value);
      
      // Get the current ad data
      const db = getFirestore(firebaseApp);
      const docRef = doc(db, `users/${auth.user.uid}/savedAds/savedAdsCollection`);
      const docSnap = await getDoc(docRef);
      
      if (!docSnap.exists()) {
        throw new Error('No saved ads found');
      }
  
      const ads = docSnap.data().ads;
      const adIndex = ads.findIndex(ad => ad.adArchiveID === adArchiveID);
      
      if (adIndex === -1) {
        throw new Error('Ad not found');
      }
  
      const ad = ads[adIndex];
  
      // Prepare the regeneration data
      const regenerationData = {
        adId: adArchiveID,
        profileData: profileData,
        originalCopywriting: ad.copywriting?.[0] || '',
        selectedImageIndices: selectedImages,
        originalImages: ad.images || [],
        targetPlatform: 'facebook',
      };
  
      const response = await fetch('/api/regenerate-images', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(regenerationData),
      });
  
      if (!response.ok) {
        throw new Error('Failed to regenerate images');
      }
  
      const { images: newImages } = await response.json();
  
      // Update only the selected images
      const updatedImages = [...ad.images];
      selectedImages.forEach((index, i) => {
        updatedImages[index] = newImages[i];
      });
  
      // Update the document
      ads[adIndex] = {
        ...ad,
        images: updatedImages,
      };
  
      await setDoc(docRef, { ads });
  
      // Update local state
      if (selectedData && selectedData.adArchiveID === adArchiveID) {
        setSelectedData({
          ...selectedData,
          images: updatedImages,
        });
      }
  
      toast.success('Selected images regenerated successfully!');
      handleCloseImageEditor();
    } catch (error) {
      console.error('Error regenerating images:', error);
      toast.error(error.message || 'Failed to regenerate images');
    } finally {
      setIsRegeneratingImage(false);
    }
  };
  const handleOpenCopywritingModal = (data) => {
    setSelectedCopywritingData(data);
    setRegeneratedCopywriting('');
    setIsCopywritingModalOpen(true);
  };
  
  const handleCloseCopywritingModal = () => {
    setIsCopywritingModalOpen(false);
    setSelectedCopywritingData(null);
    setRegeneratedCopywriting('');
    setSelectedCopyProfile(null);
  };
  
  const handleExtractAngleUSP = async () => {
    if (!selectedCopywritingData?.copywriting?.[0]) {
      toast.error('No copywriting content to analyze');
      return;
    }
  
    setIsRegeneratingCopy(true);
    try {
      const query = `Analyze this ad copy and extract the key elements. Format your response in the following structure:
        USP (Unique Selling Propositions):
        - List each USP in bullet points
        - One USP per line
        
        Content Angles:
        - List each content angle in bullet points
        - One angle per line
        
        Emotional Triggers:
        - List emotional triggers used
        - One per line
        
        Ad Copy to analyze: ${selectedCopywritingData.copywriting[0]}`;
      
      let id;
      if (selectedLanguage === "Chinese") {
        id = "angle-usp-extract-cn"
      } else if (selectedLanguage === "English") {
        id = "angle-usp-extract-en"
      } else if (selectedLanguage === "Malay") {
        id = "angle-usp-extract-malay"
      }
      const credit = 0.2
      const payload = { id, query, credit }
  
      const analysis = await toast.promise(
        fetchDataWithoutTyping(payload, auth),
        {
          loading: "Analyzing content...",
          success: "Analysis complete!",
          error: "Failed to analyze content.",
        }
      );
  
      // Format the response with HTML tags for proper rendering
      const formattedAnalysis = analysis
        .replace(/USP \(Unique Selling Propositions\):/g, '<h3>USP (Unique Selling Propositions):</h3>')
        .replace(/Content Angles:/g, '<h3>Content Angles:</h3>')
        .replace(/Emotional Triggers:/g, '<h3>Emotional Triggers:</h3>')
        .replace(/- /g, '<br>• ')
        .replace(/\n/g, '<br>');
  
      const separator = '<br><br><div style="text-align: center; font-weight: bold; margin: 20px 0;">============== Analysis ==============</div><br>';
      const updatedContent = `${separator}${formattedAnalysis}`;
      setRegeneratedCopywriting(updatedContent);
  
    } catch (error) {
      console.error('Error analyzing content:', error);
      toast.error(error.message || 'Failed to analyze content');
    } finally {
      setIsRegeneratingCopy(false);
    }
  };

  const handleRegenerateCopywriting = async () => {
    if (!selectedCopyProfile) {
      toast.error('Please select a profile first');
      return;
    }
  
    setIsRegeneratingCopy(true);
    try {
      const profileData = await loadProfileData(auth.user.uid, selectedCopyProfile.value);
      
      const query = `Original Ad Copy: ${selectedCopywritingData.copywriting[0]}. 
      Product Information: Product Name: ${profileData.productName}, 
      Product USP: ${profileData.productUSP}, 
      Product Ingredients: ${profileData.productIngredients}, 
      Product Content: ${profileData.productContent},
      Product Certification: ${profileData.productCert}`;
      
      let id;
      if (selectedLanguage === "Chinese") {
        id = "copywriting-regenerate-cn"
      } else if (selectedLanguage === "English") {
        id = "copywriting-regenerate-en"
      } else if (selectedLanguage === "Malay") {
        id = "copywriting-regenerate-malay"
      }
      let credit = 0.5
      let payload = { id, query, credit }
  
      const newCopywriting = await toast.promise(
        fetchDataWithoutTyping(payload, auth),
        {
          loading: "Regenerating copywriting...",
          success: "Copywriting regenerated!",
          error: "Failed to regenerate copywriting.",
        }
      );
  
      setRegeneratedCopywriting(newCopywriting);
    } catch (error) {
      console.error('Error regenerating copywriting:', error);
      toast.error(error.message || 'Failed to regenerate copywriting');
    } finally {
      setIsRegeneratingCopy(false);
    }
  };
  
  const saveCopywriting = async () => {
    if (!regeneratedCopywriting) {
      toast.error('No regenerated copywriting to save');
      return;
    }
  
    try {
      const db = getFirestore(firebaseApp);
      const docRef = doc(db, `users/${auth.user.uid}/savedAds/savedAdsCollection`);
      const docSnap = await getDoc(docRef);
  
      if (docSnap.exists()) {
        let ads = docSnap.data().ads || [];
        const adIndex = ads.findIndex(
          (ad) => ad.adArchiveID === selectedCopywritingData.adArchiveID
        );
  
        if (adIndex !== -1) {
          ads[adIndex].copywriting = [regeneratedCopywriting];
          await setDoc(docRef, { ads });
          toast.success('Copywriting updated successfully!');
          handleCloseCopywritingModal();
        }
      }
    } catch (error) {
      console.error('Error saving copywriting:', error);
      toast.error('Failed to save copywriting');
    }
  };

  const downloadCopywriting = () => {
    if (!regeneratedCopywriting) {
      toast.error('No regenerated copywriting to download');
      return;
    }
  
    // Create a blob with the copywriting content
    const blob = new Blob([regeneratedCopywriting], { type: 'text/plain' });
    // Create a download link and trigger it
    saveAs(blob, `copywriting_${selectedCopywritingData.adArchiveID}.txt`);
    toast.success('Copywriting downloaded successfully!');
  };
  
  const CopywritingModal = () => {
    const [selectedLanguage, setSelectedLanguage] = useState("Chinese");

    return ( 
      <Modal
        open={isCopywritingModalOpen}
        onClose={handleCloseCopywritingModal}
        className={classes.modal}
      >
        <div className={classes.modalContent}>
          <Box display="flex" flexDirection="column" height="100%">
            <Box mb={2} flexShrink={0}> {/* Add flexShrink={0} to prevent header from shrinking */}
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <Typography variant="h6">Edit Copywriting</Typography>
                <Box display="flex" alignItems="center" gap={1} mb={2}>
                  <FormControl variant="outlined" size="small" style={{ width: 120 }}>
                    <Select
                      value={selectedLanguage}
                      onChange={(e) => setSelectedLanguage(e.target.value)}
                    >
                      <MenuItem value="Chinese">Chinese</MenuItem>
                      <MenuItem value="English">English</MenuItem>
                      <MenuItem value="Malay">Malay</MenuItem>
                    </Select>
                  </FormControl>
                  <IconButton onClick={handleCloseCopywritingModal} size="small">
                    <CloseIcon />
                  </IconButton>
                </Box>
              </Box>
    
              <MemoizedSelect
                isClearable
                className={classes.profileSelect}
                placeholder="Select profile..."
                options={profiles}
                value={selectedCopyProfile}
                onChange={setSelectedCopyProfile}
                styles={{
                  menu: (base) => ({
                    ...base,
                    zIndex: 9999
                  })
                }}
              />
            </Box>
    
            <Grid container spacing={3} className={classes.gridContainer}>
              <Grid item xs={12} md={6} style={{ height: '100%' }}>
                <Box className={classes.scriptSection}>
                  <Typography variant="subtitle1" mb={2}>Original Copywriting</Typography>
                  <Box className={classes.scriptContent}>
                    {ReactHtmlParser(selectedCopywritingData?.copywriting[0] || '')}
                  </Box>
                </Box>
              </Grid>
    
              <Grid item xs={12} md={6} style={{ height: '100%' }}>
                <Box className={classes.scriptSection}>
                  <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                    <Typography variant="subtitle1">Regenerated Copywriting</Typography>
                    {regeneratedCopywriting && (
                      <IconButton
                        onClick={() => handleCopyContent(regeneratedCopywriting)}
                        size="small"
                        title="Copy content"
                      >
                        <ContentCopyIcon fontSize="small" />
                      </IconButton>
                    )}
                  </Box>
                  <Box className={classes.scriptContent}>
                    {regeneratedCopywriting ? 
                      ReactHtmlParser(regeneratedCopywriting) : 
                      <Typography color="textSecondary">
                        Click regenerate to create new copywriting
                      </Typography>
                    }
                  </Box>
                </Box>
              </Grid>
            </Grid>
    
            <Box display="flex" justifyContent="flex-end" mt={3} flexShrink={0}>
              {/* Cancel Button */}
              <Button
                variant="contained"
                onClick={handleCloseCopywritingModal}
                color="inherit"
                style={{ marginRight: theme.spacing(1) }}
              >
                Cancel
              </Button>
              {/* Extract USP & Content Angle Button */}
              <Button
                variant="contained"
                onClick={handleExtractAngleUSP}
                disabled={!selectedCopywritingData || isRegeneratingCopy}
                startIcon={<AssessmentIcon />}
                color="primary"
                style={{ marginRight: theme.spacing(1) }}
              >
                USP & Angle
              </Button>
              {/* Regenerate Button */}
              <Button
                variant="contained"
                onClick={handleRegenerateCopywriting}
                disabled={isRegeneratingCopy || !selectedCopyProfile}
                startIcon={isRegeneratingCopy ? <CircularProgress size={20} /> : <AutorenewIcon />}
                className={classes.regenerateButton}
                style={{ marginRight: theme.spacing(1) }}
              >
                {isRegeneratingCopy ? 'Regenerating...' : 'Regenerate'}
              </Button>
              {/* Download Button */}
              <Button
                variant="contained"
                onClick={downloadCopywriting}
                disabled={!regeneratedCopywriting}
                startIcon={<GetAppIcon />}
                color="primary"
              >
                Download
              </Button>
            </Box>
          </Box>
        </div>
      </Modal>
    )
  };

  const VideoModal = () => {
    const [selectedLanguage, setSelectedLanguage] = useState("Chinese");

    return (
      <Modal
        open={open}
        onClose={handleClose}
        className={classes.modal}
      >
        <div className={classes.modalContent}>
          <Grid container spacing={3} className={classes.gridContainer}>
            <Grid item xs={12} md={6} style={{ height: '100%' }}>
              <div className={classes.videoSection}>
                <video
                  controls
                  className={classes.video}
                  src={selectedData?.videos?.[0]}
                >
                  Your browser does not support the video tag.
                </video>
              </div>
            </Grid>
            
            <Grid item xs={12} md={6} style={{ height: '100%' }}>
              <div className={classes.scriptSection}>
                <div className={classes.scriptHeader} style={{ flexDirection: 'column', gap: '8px' }}>
                  <Typography variant="h6" style={{ marginBottom: '4px' }}>Script</Typography>
                  <Box display="flex" alignItems="center" gap={1}>
                    {/* commented to not interfere with event flow */}
                    {/* <FormControl variant="outlined" size="small" style={{ width: 120 }}>
                      <Select
                        value={selectedLanguage}
                        onChange={(e) => setSelectedLanguage(e.target.value)}
                      >
                        <MenuItem value="Chinese">Chinese</MenuItem>
                        <MenuItem value="English">English</MenuItem>
                        <MenuItem value="Malay">Malay</MenuItem>
                      </Select>
                    </FormControl> */}
                    <IconButton onClick={handleCloseCopywritingModal} size="small">
                      <CloseIcon />
                    </IconButton>
                  </Box>
                  {selectedData?.script ? (
                    <div>
                      <MemoizedSelect
                        isClearable
                        className={classes.profileSelect}
                        placeholder="Select profile..."
                        options={profiles}
                        value={selectedProfile}
                        onChange={setSelectedProfile}
                        styles={{
                          control: (base) => ({
                            ...base,
                            minWidth: '200px',
                          }),
                          menu: (base) => ({
                            ...base,
                            zIndex: 9999
                          })
                        }}
                      />
                      <Button
                        variant="contained"
                        className={classes.regenerateButton}
                        onClick={handleRegenerateScript}
                        disabled={isRegenerating || !selectedProfile}
                        startIcon={isRegenerating ? <CircularProgress size={20} color="inherit" /> : <AutorenewIcon />}
                      >
                        {isRegenerating ? 'Regenerating...' : 'Regenerate'}
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        startIcon={<FileCopyIcon />}
                        style={{ marginRight: theme.spacing(1) }}
                      >
                        <CopyToClipboard
                          text={selectedData.script.replace(/<br>/g, "\n")}
                          onCopy={() => toast.success("Successfully copied!")}
                          options={{ format: "text/plain" }}
                        >
                          <span>Copy</span>
                        </CopyToClipboard>
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        startIcon={<GetAppIcon />}
                        onClick={downloadScript}
                        style={{ marginRight: theme.spacing(1) }}
                      >
                        Download
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        startIcon={<SaveIcon />}
                        onClick={saveEditedScript}
                      >
                        Save
                      </Button>
                    </div>
                  ) : (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleTranscribe}
                      disabled={isTranscribing}
                      startIcon={isTranscribing ? <CircularProgress size={20} color="inherit" /> : null}
                    >
                      {isTranscribing ? 'Transcribing...' : '剧本稿件'}
                    </Button>
                  )}
                </div>
                <div className={classes.scriptContent}>
                  {selectedData?.script ? (
                    <TextEditor
                      script={selectedData.script}
                      isEditMode={isEditMode}
                      onContentChange={handleContentChange}
                    />
                  ) : (
                    <Box 
                      display="flex" 
                      justifyContent="center" 
                      alignItems="center" 
                      height="100%"
                      bgcolor="white"
                      borderRadius={1}
                    >
                      <Typography color="textSecondary">
                        No script available. Click the button above to transcribe.
                      </Typography>
                    </Box>
                  )}
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </Modal>
    )
  };

  const toggleTruncateText = (index) => {
    setExpandedDiv(expandedDiv === index ? null : index);
  };

  const handleTabChange = (event, newValue) => {
    activeTab === "images" ? setActiveTab("videos") : setActiveTab("images");
  };
  const handleFilterChange = (selectedValue) => {
    // console.log(selectedValue)
    setFilterValue(selectedValue);
    setCurrentPage(1);
  };

  const handleFilterResultsChange = (selectedValue) => {
    // console.log(selectedValue)
    setFilterResults(selectedValue);
    // setCurrentPage(1);
  };

  const handleCardClick = (index) => {
    setSelectedCards((prevSelectedCards) => {
      if (prevSelectedCards.includes(index)) {
        // If the card is already selected, remove it from the selection
        return prevSelectedCards.filter((cardIndex) => cardIndex !== index);
      } else {
        // If the card is not selected, add it to the selection
        return [...prevSelectedCards, index];
      }
    });
  };

  const handleClearSelection = () => {
    setSelectedCards([]);
  };

  const handleContentChange = (content) => {
    setEditorContent(content);
  };

  const handleCopyContent = async (content) => {
    try {
      // Create a temporary element with the formatted content
      const formattedContent = content
        .replace(/<h3>/g, '\n\n') // Add extra line breaks before headers
        .replace(/<\/h3>/g, '\n') // Add line break after headers
        .replace(/<br>/g, '\n') // Convert <br> to newlines
        .replace(/<div[^>]*>/g, '\n') // Convert divs to newlines
        .replace(/<\/div>/g, '') // Remove closing div tags
        .replace(/•/g, '  •') // Add spacing before bullets
        .replace(/=+/g, '='); // Preserve separator lines
  
      // Create blob with HTML content for rich text copying
      const blob = new Blob([`
        <html>
          <body>
            <pre style="font-family: Arial, sans-serif; white-space: pre-wrap;">${formattedContent}</pre>
          </body>
        </html>
      `], { type: 'text/html' });
  
      // Create ClipboardItem for rich text
      const clipboardItem = new ClipboardItem({
        'text/html': blob,
        'text/plain': new Blob([formattedContent], { type: 'text/plain' })
      });
  
      await navigator.clipboard.write([clipboardItem]);
      toast.success('Content copied to clipboard!');
    } catch (err) {
      console.error('Failed to copy:', err);
      // Fallback to basic text copying if rich copy fails
      try {
        await navigator.clipboard.writeText(content);
        toast.success('Content copied to clipboard (plain text)!');
      } catch (fallbackErr) {
        toast.error('Failed to copy content');
      }
    }
  };

  // Save data
  const saveEditedScript = async () => {
    const updatedScript = editorContent;
    const db = getFirestore(firebaseApp);
    const docRef = doc(
      db,
      `users/${auth.user.uid}/savedAds/savedAdsCollection`
    );
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      let ads = docSnap.data().ads || [];
      const adIndex = ads.findIndex(
        (ad) => ad.adArchiveID === selectedData.adArchiveID
      );

      if (adIndex !== -1) {
        ads[adIndex].script = updatedScript;
        await setDoc(docRef, { ads });
        console.log("Script updated for ad:", selectedData.adArchiveID);
      }
    }
  };

  const saveMedia = async (index) => {
    // Get the correct data from filteredItems which is what's displayed to the user
    const data = filteredItems[index];
    const promises = [];

    if (data.images) {
      data.images.forEach((image, i) => {
        promises.push(
          (async () => {
            const response = await fetch(image); // Assuming `image` is a direct URL from Cloudflare R2
            const blob = await response.blob();
            saveAs(blob, `image_${data.adArchiveID}_${i}.png`);
          })()
        );
      });
    }

    if (data.videos) {
      data.videos.forEach((video, i) => {
        promises.push(
          (async () => {
            const response = await fetch(video); // Assuming `video` is a direct URL from Cloudflare R2
            const blob = await response.blob();
            saveAs(blob, `video_${data.adArchiveID}_${i}.mp4`);
          })()
        );
      });
    }

    // Wait for all promises to resolve
    await Promise.all(promises);
    toast.success('Media downloaded successfully!');
  };

  const handleOpenModal = () => {
    setOpen(true);
    setIsEditMode(false);
  };

  // Fetch the document
  const fetchData = () => {
    const db = getFirestore(firebaseApp);
    // console.log("selectedValue:", selectedValue)
    // console.log("filterValue:", filterValue)

    const userDocPath = `users/${auth.user.uid}/savedAds/savedAdsCollection`;

    const docRef = doc(db, userDocPath);

    // Listen for real-time updates
    const unsubscribe = onSnapshot(docRef, (docSnap) => {
      if (docSnap.exists()) {
        const data = docSnap.data();
        const items = data.ads;
        // console.log(items)
        setUsageData(items);
      } else {
        console.log("No such document!");
      }
    });

    // Return the unsubscribe function to clean up the listener
    return unsubscribe;
  };

   const handleTranscribe = async () => {
     setIsTranscribing(true);
     try {
       await toast.promise(
         transcribeVideo(selectedData.adArchiveID),
         {
           loading: 'Transcribing video...',
           success: 'Transcription complete!',
           error: 'Failed to transcribe video.'
         }
       );
     } finally {
       setIsTranscribing(false);
     }
   };

  const transcribeVideo = async (adArchiveID) => {
    const db = getFirestore(firebaseApp);
    const docRef = doc(
      db,
      `users/${auth.user.uid}/savedAds/savedAdsCollection`
    );
    const docSnap = await getDoc(docRef);

    if (!docSnap.exists()) {
      console.log("Document not found, cannot update transcription.");
      return;
    }

    let ads = docSnap.data().ads || [];
    const adIndex = ads.findIndex((ad) => ad.adArchiveID === adArchiveID);

    if (adIndex === -1) {
      console.error("No matching ad found for adArchiveID:", adArchiveID);
      return;
    }

    const ad = ads[adIndex];
    if (!ad.videos || ad.videos.length === 0) {
      console.log("No videos available for transcription for ad:", adArchiveID);
      return;
    }

    const videoUrl = ad.videos[0];
    try {
      const response = await fetch("https://ctg-server-3.fly.dev/convert", {
        method: "POST",
        credentials: 'include', 
        mode: 'cors',
        body: JSON.stringify({ videoUrl, uid: auth.user.uid }),
        headers: {
          "Content-Type": "application/json",
          "Accept": "application/json",
        },
      });
      const transcriptionData = await response.json();
      if (transcriptionData && transcriptionData.transcription) {
        ad.script = transcriptionData.transcription;
        await setDoc(docRef, { ads });
        setUsageData([...ads]);
        if (selectedData && selectedData.adArchiveID === adArchiveID) {
          setSelectedData({ ...selectedData, script: transcriptionData.transcription });
        }
        console.log("Transcription updated for ad:", adArchiveID);
      }
    } catch (error) {
      console.error("Failed to transcribe video for ad:", adArchiveID, error);
    }
  };

  // Fetch data on component mount
  useEffect(() => {
    const unsubscribe = fetchData();

    return unsubscribe;
  }, [filterValue]);

  // Modal handlers
  const handleOpen = (data) => {
    setSelectedData(data);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleModalNext = () => {
    setCurrentIndex((prevIndex) => prevIndex + 1);
  };

  const handleModalPrev = () => {
    setCurrentIndex((prevIndex) => prevIndex - 1);
  };

  const carouselProps = {
    showThumbs: false,
    showStatus: false,
    dynamicHeight: true,
    width: "200px",
    infiniteLoop: true,
    autoPlay: true,
    stopOnHover: true,
    showIndicators: false,
  };  

  const MemoizedSelect = React.memo(({ value, onChange, options, ...props }) => (
    <CreatableSelect
      value={value}
      onChange={onChange}
      options={options}
      {...props}
    />
  ));

  return (
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container>
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          size={4}
          textAlign="center"
        />
        <Toaster position="bottom-right" reverseOrder={false} />

        {usageData.length > itemsPerPage && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={() => setCurrentPage((old) => Math.max(old - 1, 1))}
              disabled={currentPage === 1}
            >
              Prev
            </Button>

            <Typography variant="subtitle2">
              Page {currentPage} of {totalPages}
            </Typography>

            <Button
              variant="contained"
              color="primary"
              onClick={() =>
                setCurrentPage((old) => Math.min(old + 1, totalPages))
              }
              disabled={currentPage === totalPages}
            >
              Next
            </Button>
          </Box>
        )}
        <Tabs
          value={activeTab === "images" ? 0 : 1}
          onChange={handleTabChange}
          centered
        >
          <Tab label="Images" />
          <Tab label="Videos" />
        </Tabs>
        <Grid container>
          {filteredItems.map((data, index) => {
            return (
              <Grid item xs={12} sm={6} md={4}>
                <Box display="flex" justifyContent="center" alignItems="center">
                  <Card
                    className={classes.squareCard}
                    key={index}
                    // onClick={() => handleCardClick(index)}
                    style={{
                      // backgroundColor: selectedCards.includes(index)
                      //   ? "#f0f0f0"
                      //   : "white",
                      border: selectedCards.includes(index)
                        ? "1px solid #087F8C"
                        : "none",
                      boxShadow: selectedCards.includes(index)
                        ? "0px 0px 10px 2px rgba(63,81,181,0.5)"
                        : "0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12)",
                      transition: "all 0.1s ease",
                    }}
                  >
                    <Box className={classes.cardActionArea}>
                      <CardContent style={{ position: "relative" }}>
                        <Box
                          style={{
                            position: "absolute",
                            top: 10,
                            right: 10,
                            display: "flex",
                            gap: "1rem",
                            background: "#f0f0f0",
                            padding: "4px",
                            borderRadius: "8px",
                          }}
                        >
                          {activeTab === "videos" && (
                            <Tooltip title="Script">
                              <DescriptionTwoToneIcon
                                fontSize="medium"
                                className={classes.getAppIconStyle}
                                onClick={(event) => {
                                  setSelectedData(data);
                                  handleOpenModal();
                                }}
                              />
                            </Tooltip>
                          )}
                          <Tooltip title="Download">
                            <GetAppIcon
                              fontSize="medium"
                              className={classes.getAppIconStyle}
                              onClick={(event) => {
                                event.stopPropagation();
                                saveMedia(index);
                              }}
                            />
                          </Tooltip>
                        </Box>
                        <Box mb={1}>
                          <Typography variant="body2">
                            Library ID: {data.adArchiveID}
                          </Typography>
                        </Box>

                        <Box mb={1}>
                          <Typography variant="body2">
                            Started running on{" "}
                            <strong>
                              {new Date(
                                data.startDate * 1000
                              ).toLocaleDateString()}
                            </strong>
                          </Typography>
                        </Box>

                        <Box mb={1}>
                          <Typography variant="body2">
                            <strong>{data.collationCount} ads</strong> using
                            this creative and text
                          </Typography>
                        </Box>
                        <Divider
                          style={{ marginTop: "1rem", marginBottom: "1rem" }}
                        />

                        <Box display="flex" alignItems="center" mb={3}>
                          <Avatar
                            style={{ width: "2rem", height: "2rem" }}
                            src={data.page_profile_picture}
                          />
                          <Box ml={1.5}>
                            <Typography
                              style={{ fontWeight: "900" }}
                              variant="body2"
                              component="h4"
                            >
                              {data.page_name}
                            </Typography>
                            <Typography
                              style={{ fontWeight: "700" }}
                              variant="caption"
                              color="textSecondary"
                            >
                              Sponsored
                            </Typography>
                          </Box>
                        </Box>

                        <Box mb={3}>
                          <div style={{ 
                            position: 'relative',
                          }}>
                            {/* <IconButton
                              size="small"
                              style={{
                                position: 'absolute',
                                top: 0,
                                right: 0,
                                backgroundColor: theme.palette.background.paper,
                                boxShadow: theme.shadows[1],
                                padding: '4px',
                                '&:hover': {
                                  backgroundColor: theme.palette.grey[100],
                                }
                              }}
                              onClick={(e) => {
                                e.stopPropagation();
                                handleOpenCopywritingModal(data);
                              }}
                            >
                              <EditIcon fontSize="small" />
                            </IconButton> */}
                            {data &&
                              data.copywriting &&
                              data.copywriting.length > 0 && (
                                <div onClick={(e) => {
                                  e.stopPropagation();
                                  // toggleTruncateText(index);
                                  handleOpenCopywritingModal(data);
                                }}>
                                  {ReactHtmlParser(
                                    expandedDiv === index
                                      ? data.copywriting[0]
                                      : `${data.copywriting[0].substring(0, 100)} ...`
                                  )}
                                </div>
                              )}
                          </div>
                        </Box>
                        <CopywritingModal />

                        {data && data.images && data.images.length > 0 && (
                          <Box
                            bgcolor={theme.palette.grey[50]}
                            display="flex"
                            flexDirection="column"
                            gap={2}
                            p={2}
                            borderRadius={1}
                          >
                            <Box display="flex" justifyContent="space-between" alignItems="center">
                              <Typography variant="subtitle2">
                                {data.images.length} {data.images.length === 1 ? 'Image' : 'Images'}
                              </Typography>
                              <IconButton
                                size="small"
                                onClick={() => handleOpenImageEditor(data)}
                                title="Edit image text"
                              >
                                <EditIcon fontSize="small" />
                              </IconButton>
                            </Box>
                            
                            <Box display="flex" justifyContent="center" alignItems="center" width="100%">
                              <Carousel {...carouselProps}>
                                {data.images.map((image, index) => (
                                  <div key={index}>
                                    <img src={image} alt={`Ad image ${index + 1}`} />
                                  </div>
                                ))}
                              </Carousel>
                            </Box>
                          </Box>
                        )}
                        <Modal
                          open={isImageEditorOpen}
                          onClose={handleCloseImageEditor}
                          className={classes.modal}
                        >
                          <Box className={classes.imageEditorModal}>
                            <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                              <Typography variant="h6">Edit Image Text</Typography>
                              <IconButton onClick={handleCloseImageEditor} size="small">
                                <CloseIcon />
                              </IconButton>
                            </Box>

                            <Box display="flex" gap={2} mb={3}>
                              <MemoizedSelect
                                isClearable
                                placeholder="Select profile..."
                                options={profiles}
                                value={selectedImageProfile}
                                onChange={setSelectedImageProfile}
                                styles={{
                                  control: (base) => ({
                                    ...base,
                                    minWidth: '200px',
                                  }),
                                  menu: (base) => ({
                                    ...base,
                                    zIndex: 9999
                                  })
                                }}
                              />
                            </Box>

                            <Grid container spacing={2}>
                              {selectedAdData?.images.map((image, index) => (
                                <Grid item xs={12} sm={6} md={4} key={index}>
                                  <Card>
                                    <CardMedia
                                      component="img"
                                      image={image}
                                      alt={`Ad image ${index + 1}`}
                                      sx={{ height: 200, objectFit: 'contain' }}
                                    />
                                    <CardContent>
                                      <Box display="flex" justifyContent="space-between" alignItems="center">
                                        <Typography variant="body2">Image {index + 1}</Typography>
                                        <Checkbox
                                          checked={selectedImages.includes(index)}
                                          onChange={() => handleImageSelection(index)}
                                        />
                                      </Box>
                                    </CardContent>
                                  </Card>
                                </Grid>
                              ))}
                            </Grid>

                            <Box display="flex" justifyContent="flex-end" gap={2} mt={3}>
                              <Button
                                variant="contained"
                                onClick={handleCloseImageEditor}
                                color="inherit"
                              >
                                Cancel
                              </Button>
                              <Button
                                variant="contained"
                                onClick={() => handleRegenerateSelectedImages(selectedAdData?.adArchiveID)}
                                disabled={isRegeneratingImage || !selectedImageProfile || selectedImages.length === 0}
                                startIcon={isRegeneratingImage ? <CircularProgress size={20} /> : <AutorenewIcon />}
                                className={classes.regenerateButton}
                              >
                                {isRegeneratingImage ? 'Regenerating...' : 'Regenerate Selected'}
                              </Button>
                            </Box>
                          </Box>
                        </Modal>
                        {data && data.videos && data.videos[0] && (
                          <>
                            <Box
                              bgcolor={theme.palette.grey[50]}
                              display="flex"
                              flexDirection="column"
                              justifyContent="center"
                              alignItems="center"
                              width="100%"
                              height="100%"
                            >
                              <video
                                src={data.videos[0]}
                                alt="Winning ads video"
                                style={{ width: "200px", height: "200px" }}
                                controls
                              />
                            </Box>
                          </>
                        )}
                        <VideoModal />
                      </CardContent>
                    </Box>
                  </Card>
                </Box>
              </Grid>
            );
          })}
        </Grid>
      </Container>
    </Section>
  );
};

export default ScraperHistorySection;
