import React from "react";
import Meta from "../../components/Meta";
import { requireProUser } from "../../util/auth";
import { CwRegenerator } from "../../components/regenerator/CwRegenerator";

function CwRegeneratorPage(props) {
  return (
    <>
      <Meta title="Copywriting Regenerator" />
      <CwRegenerator />
    </>
  );
}

export default requireProUser(CwRegeneratorPage);
