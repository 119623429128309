import React from "react";
import Meta from "../../components/Meta";
import { requireProUser } from "../../util/auth";
import { VideoRegenerator } from "../../components/regenerator/VideoRegenerator";

function VideoRegeneratorPage(props) {
  return (
    <>
      <Meta title="Video Regenerator" />
      <VideoRegenerator />
    </>
  );
}

export default requireProUser(VideoRegeneratorPage);
