import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Typography,
  CircularProgress,
  Grid,
  IconButton,
  Container,
  Divider,
  makeStyles,
  Paper,
  FormControl,
  Select,
  MenuItem,
} from "@material-ui/core";
import CreatableSelect from "react-select/creatable";
import { saveAs } from "file-saver";
import { useAuth } from "../../util/auth";
import { Toaster, toast } from "react-hot-toast";
import AutorenewIcon from "@material-ui/icons/Autorenew";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import GetAppIcon from "@material-ui/icons/GetApp";
import EditIcon from "@material-ui/icons/Edit";
import SaveIcon from "@material-ui/icons/Save";
import { retrieveProfiles, loadProfileData } from "../../util/db.js";
import { fetchDataWithoutTyping } from "../functionalComponent/fetchData";
import Section from "../Section";
import SectionHeader from "../SectionHeader";
import { TextEditor } from "../richtexteditor/TextEditor";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
    height: "100%",
  },
  profileSelect: {
    marginBottom: theme.spacing(3),
  },
  gridContainer: {
    flexGrow: 1,
    height: "calc(100% - 200px)",
  },
  scriptSection: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  scriptContent: {
    flex: 1,
    padding: theme.spacing(2),
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    overflowY: "auto",
    backgroundColor: theme.palette.background.paper,
    "& p": {
      marginBottom: theme.spacing(1),
    },
  },
  regenerateButton: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.common.white,
    "&:hover": {
      backgroundColor: theme.palette.success.dark,
    },
  },
}));

const MemoizedSelect = React.memo(({ value, onChange, options, ...props }) => (
  <CreatableSelect value={value} onChange={onChange} options={options} {...props} />
));

export function ReplyRegenerator() {
  const classes = useStyles();
  const auth = useAuth();
  const [selectedProfile, setSelectedProfile] = useState(null);
  const [customerMessage, setCustomerMessage] = useState("");
  const [regeneratedReply, setRegeneratedReply] = useState("");
  const [isRegenerating, setIsRegenerating] = useState(false);
  const [profiles, setProfiles] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState("Chinese");
  const [isEditMode, setIsEditMode] = useState(false);
  const [isGeneratingIdeas, setIsGeneratingIdeas] = useState(false);

  const CHAR_LIMIT = 1000;

  useEffect(() => {
    if (auth.user) {
      retrieveProfiles(auth.user.uid).then(setProfiles);
    }
  }, [auth.user]);

  const handleCustomerMessageChange = (content) => {
    if (!content) {
      setCustomerMessage("");
      return;
    }

    const plainText = content.replace(/<[^>]*>/g, "");

    if (plainText.length <= CHAR_LIMIT) {
      setCustomerMessage(content);
    } else {
      if (customerMessage !== plainText.slice(0, CHAR_LIMIT)) {
        const truncatedText = plainText.slice(0, CHAR_LIMIT);
        setCustomerMessage(truncatedText);
        toast.error(`Content exceeds ${CHAR_LIMIT} character limit. Content has been truncated.`);
      }
    }
  };

  const handleRegenerateReply = async () => {
    if (!selectedProfile) {
      toast.error("Please select a profile first");
      return;
    }

    setIsRegenerating(true);
    try {
      const profileData = await loadProfileData(auth.user.uid, selectedProfile.value);

      const query = `Existing Reply: ${customerMessage}
      Product Information: 
      Product Name: ${profileData.productName}
      Product USP: ${profileData.productUSP}
      Product Details: ${profileData.productContent}
      Please generate a professional customer service reply.`;

      let id =
        selectedLanguage === "Chinese"
          ? "reply-regenerate-cn"
          : selectedLanguage === "English"
          ? "reply-regenerate-en"
          : "reply-regenerate-malay";

      const payload = { id, query, credit: 0.3 };

      const newReply = await toast.promise(fetchDataWithoutTyping(payload, auth), {
        loading: "Generating reply...",
        success: "Reply generated!",
        error: "Failed to generate reply.",
      });

      const formattedReply = newReply
      .split('\n')
      .filter(line => line.trim())
      .map(line => `<p>${line}</p>`)
      .join('');

      if (regeneratedReply) {
        const separator = `
          <div style="text-align: center; font-weight: bold; margin: 20px 0;">
            ============== New Reply ==============
          </div>
        `;
        setRegeneratedReply(prev => prev + separator + formattedReply);
      } else {
        setRegeneratedReply(formattedReply);
      }
    } catch (error) {
      console.error("Error generating reply:", error);
      toast.error(error.message || "Failed to generate reply");
    } finally {
      setIsRegenerating(false);
    }
  };

  const handleGenerateMoreIdeas = async () => {
    if (!selectedProfile) {
      toast.error('Please select a profile first');
      return;
    }
  
    if (!regeneratedReply) {
      toast.error('Please generate a reply first');
      return;
    }
  
    setIsGeneratingIdeas(true);
    try {
      const profileData = await loadProfileData(auth.user.uid, selectedProfile.value);
      
      const query = `Generate completely different customer service reply ideas.
      Each idea should have a unique approach and emotional appeal.
      
      Product Details:
      - Name: ${profileData.productName}
      - USP: ${profileData.productUSP}
      - Key Features: ${profileData.productContent}
  
      Previous replies to avoid repeating:
      ${regeneratedReply}
  
      Requirements:
      1. Each reply should have a distinct communication style
      2. Include empathy and professional tone
      3. Focus on different product benefits or solutions
      4. Use varied customer service approaches
      5. Format each idea with a clear title and content`;
  
      let id;
      if (selectedLanguage === "Chinese") {
        id = "reply-idea-generator-cn"
      } else if (selectedLanguage === "English") {
        id = "reply-idea-generator-en"
      } else if (selectedLanguage === "Malay") {
        id = "reply-idea-generator-malay"
      }
  
      const payload = { id, query, credit: 0.5 };
  
      const newIdeas = await toast.promise(
        fetchDataWithoutTyping(payload, auth),
        {
          loading: "Generating new ideas...",
          success: "New ideas generated!",
          error: "Failed to generate ideas.",
        }
      );
  
      // Format the response with proper HTML paragraphs
      const formattedIdeas = newIdeas
        .split('\n')
        .filter(line => line.trim())
        .map(line => `<p>${line}</p>`)
        .join('');
  
      // Format and append to regenerated content with clear separation
      const separator = `
        <div style="text-align: center; font-weight: bold; margin: 20px 0;">
          ============== More Ideas ==============
        </div>
      `;
      
      setRegeneratedReply(prev => prev + separator + formattedIdeas);
  
    } catch (error) {
      console.error('Error generating ideas:', error);
      toast.error(error.message || 'Failed to generate ideas');
    } finally {
      setIsGeneratingIdeas(false);
    }
  };

  const handleCopyContent = async (content) => {
    try {
      // Format content similar to how we generate it
      const formattedContent = content
        .replace(/<p>/g, '') // Remove opening p tags
        .replace(/<\/p>/g, '\n') // Convert closing p tags to newlines
        .replace(/<div[^>]*>/g, '\n') // Convert div openings to newlines
        .replace(/<\/div>/g, '\n') // Convert div closings to newlines
        .replace(/<br\s*\/?>/g, '\n') // Convert br tags to newlines
        .replace(/&nbsp;/g, ' ') // Convert &nbsp; to spaces
        .replace(/<[^>]*>/g, '') // Remove any remaining HTML tags
        .replace(/\n\s*\n/g, '\n\n') // Remove excessive blank lines
        .replace(/^\s+|\s+$/g, '') // Trim whitespace
        .replace(/•/g, '  •') // Add spacing before bullets
        .replace(/=+/g, '='); // Preserve separator lines
  
      // Create blob with both HTML and plain text versions
      const htmlContent = `
        <html>
          <body>
            <pre style="font-family: Arial, sans-serif; white-space: pre-wrap;">${formattedContent}</pre>
          </body>
        </html>
      `;
  
      // Create ClipboardItem with both formats
      const clipboardItem = new ClipboardItem({
        'text/html': new Blob([htmlContent], { type: 'text/html' }),
        'text/plain': new Blob([formattedContent], { type: 'text/plain' })
      });
  
      await navigator.clipboard.write([clipboardItem]);
      toast.success('Content copied to clipboard!');
    } catch (err) {
      console.error('Failed to copy:', err);
      // Fallback to basic text copying
      try {
        // Use the same formatting for fallback
        const plainText = content
          .replace(/<p>/g, '')
          .replace(/<\/p>/g, '\n')
          .replace(/<div[^>]*>/g, '\n')
          .replace(/<\/div>/g, '\n')
          .replace(/<br\s*\/?>/g, '\n')
          .replace(/&nbsp;/g, ' ')
          .replace(/<[^>]*>/g, '')
          .replace(/\n\s*\n/g, '\n\n')
          .replace(/^\s+|\s+$/g, '')
          .replace(/•/g, '  •')
          .replace(/=+/g, '=');
  
        await navigator.clipboard.writeText(plainText);
        toast.success('Content copied to clipboard (plain text)!');
      } catch (fallbackErr) {
        toast.error('Failed to copy content');
      }
    }
  };

  const downloadReply = () => {
    if (!regeneratedReply) {
      toast.error("No generated reply to download");
      return;
    }
  
    // Convert HTML to plain text while preserving line breaks
    const plainText = regeneratedReply
      .replace(/<div[^>]*>/g, '\n')
      .replace(/<\/div>/g, '')
      .replace(/<p>/g, '')
      .replace(/<\/p>/g, '\n')
      .replace(/&nbsp;/g, ' ')
      .replace(/<br\s*\/?>/g, '\n')
      .replace(/<[^>]*>/g, '')
      .replace(/\n\s*\n/g, '\n\n')
      .trim();
  
    const blob = new Blob([plainText], { type: "text/plain" });
    saveAs(blob, `customer_reply_${Date.now()}.txt`);
    toast.success("Reply downloaded successfully!");
  };

  return (
    <Section>
      <Container>
        <SectionHeader
          title="Reply Generator"
          subtitle="Generate professional customer service replies"
          size={4}
          textAlign="center"
        />
        <Toaster position="bottom-right" reverseOrder={false} />

        <Box mb={3}>
          <MemoizedSelect
            isClearable
            placeholder="Select profile..."
            options={profiles}
            value={selectedProfile}
            onChange={setSelectedProfile}
          />
        </Box>

        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <Paper style={{ minHeight: "300px", height: "650px" }}>
              <Box display="flex" justifyContent="space-between" alignItems="center" p={2}>
                <Typography variant="h5">Reply</Typography>
                <Typography variant="caption" color="textSecondary">
                  {customerMessage.replace(/<[^>]*>/g, "").length}/{CHAR_LIMIT} characters
                </Typography>
              </Box>
              <Divider />
              <Box
                p={3}
                style={{
                  height: "calc(100% - 70px)",
                  overflowY: "auto",
                  cursor: "text",
                }}
                onClick={(e) => {
                  const editorElement = e.currentTarget.querySelector('[contenteditable="true"]');
                  if (editorElement) {
                    editorElement.focus();
                  }
                }}
              >
                <TextEditor
                  script={customerMessage}
                  isEditMode={true}
                  onContentChange={handleCustomerMessageChange}
                  maxLength={CHAR_LIMIT}
                />
              </Box>
            </Paper>
          </Grid>

          <Grid item xs={12} md={6}>
            <Paper style={{ minHeight: "300px", height: "650px" }}>
              <Box display="flex" justifyContent="space-between" alignItems="center" p={2}>
                <Typography variant="h5">Generated Reply</Typography>
                <Box display="flex" alignItems="center" gap={1}>
                  <FormControl variant="outlined" size="small" style={{ width: 120 }}>
                    <Select
                      value={selectedLanguage}
                      onChange={(e) => setSelectedLanguage(e.target.value)}
                    >
                      <MenuItem value="Chinese">Chinese</MenuItem>
                      <MenuItem value="English">English</MenuItem>
                      <MenuItem value="Malay">Malay</MenuItem>
                    </Select>
                  </FormControl>
                  <IconButton
                    onClick={() => handleCopyContent(regeneratedReply)}
                    size="small"
                    title="Copy content"
                    disabled={!regeneratedReply}
                  >
                    <FileCopyIcon fontSize="small" />
                  </IconButton>
                </Box>
              </Box>
              <Divider />
              <Box
                p={3}
                style={{
                  height: "calc(100% - 70px)",
                  overflowY: "auto",
                  cursor: "text",
                }}
                onClick={(e) => {
                  const editorElement = e.currentTarget.querySelector('[contenteditable="true"]');
                  if (editorElement) {
                    editorElement.focus();
                  }
                }}
              >
                {regeneratedReply ? (
                  <TextEditor
                    script={regeneratedReply}
                    isEditMode={isEditMode}
                    onContentChange={(content) => setRegeneratedReply(content)}
                  />
                ) : (
                  <Typography color="textSecondary">Click generate to create a reply</Typography>
                )}
              </Box>
            </Paper>
          </Grid>
        </Grid>

        <Box display="flex" justifyContent="flex-end" mt={3}>
          <Button
            variant="contained"
            onClick={handleGenerateMoreIdeas}
            disabled={!regeneratedReply || isGeneratingIdeas}
            startIcon={isGeneratingIdeas ? <CircularProgress size={20} /> : <AutorenewIcon />}
            color="primary"
            style={{ marginRight: 8 }}
          >
            {isGeneratingIdeas ? 'Generating...' : 'More Ideas'}
          </Button>
          <Button
            variant="contained"
            onClick={handleRegenerateReply}
            disabled={isRegenerating || !selectedProfile}
            startIcon={isRegenerating ? <CircularProgress size={20} /> : <AutorenewIcon />}
            color="primary"
            style={{ marginRight: 8 }}
          >
            {isRegenerating ? "Generating..." : "Generate Reply"}
          </Button>
          <Button
            variant="contained"
            onClick={downloadReply}
            disabled={!regeneratedReply}
            startIcon={<GetAppIcon />}
            color="primary"
          >
            Download
          </Button>
        </Box>
      </Container>
    </Section>
  );
}
