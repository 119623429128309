import React from "react";
import Meta from "../../components/Meta";
import { requireProUser } from "../../util/auth";
import { UspRegenerator } from "../../components/regenerator/UspRegenerator";

function UspRegeneratorPage(props) {
  return (
    <>
      <Meta title="USP Regenerator" />
      <UspRegenerator />
    </>
  );
}

export default requireProUser(UspRegeneratorPage);
