import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Typography,
  CircularProgress,
  Grid,
  IconButton,
  Container,
  Divider,
  makeStyles,
  Paper,
  FormControl,
  Select,
  MenuItem,
} from '@material-ui/core';
import ReactHtmlParser from '@orrisroot/react-html-parser';
import CreatableSelect from 'react-select/creatable';
import { saveAs } from "file-saver";
import { useAuth } from "../../util/auth";
import { Toaster, toast } from "react-hot-toast";
import AutorenewIcon from '@material-ui/icons/Autorenew';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import GetAppIcon from '@material-ui/icons/GetApp';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import AssessmentIcon from '@material-ui/icons/Assessment';
import { retrieveProfiles, loadProfileData } from '../../util/db.js';
import { fetchDataWithoutTyping } from "../functionalComponent/fetchData";
import Section from "../Section";
import SectionHeader from "../SectionHeader";
import { TextEditor } from "../richtexteditor/TextEditor";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
    height: '100%',
  },
  profileSelect: {
    marginBottom: theme.spacing(3),
  },
  gridContainer: {
    flexGrow: 1,
    height: 'calc(100% - 200px)', // Adjust based on header and footer height
  },
  scriptSection: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  scriptContent: {
    flex: 1,
    padding: theme.spacing(2),
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    overflowY: 'auto',
    backgroundColor: theme.palette.background.paper,
    '& p': {
      marginBottom: theme.spacing(1),
    },
  },
  regenerateButton: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.success.dark,
    },
  },
}));

const MemoizedSelect = React.memo(({ value, onChange, options, ...props }) => (
  <CreatableSelect
    value={value}
    onChange={onChange}
    options={options}
    {...props}
  />
));

export function CwRegenerator() {
  const classes = useStyles();
  const auth = useAuth();
  const [selectedCopyProfile, setSelectedCopyProfile] = useState(null);
  const [originalCopywriting, setOriginalCopywriting] = useState('');
  const [regeneratedCopywriting, setRegeneratedCopywriting] = useState('');
  const [isRegeneratingCopy, setIsRegeneratingCopy] = useState(false);
  const [profiles, setProfiles] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState("Chinese");
  const [editorContent, setEditorContent] = useState('');
  const [isEditMode, setIsEditMode] = useState(false);
  const [isGeneratingIdeas, setIsGeneratingIdeas] = useState(false);

  const CHAR_LIMIT = 2000;

  useEffect(() => {
    if (auth.user) {
      retrieveProfiles(auth.user.uid).then(setProfiles);
    }
  }, [auth.user]);

  const handleOriginalContentChange = (content) => {
    // Prevent processing if content is empty
    if (!content) {
      setOriginalCopywriting('');
      return;
    }
  
    // Strip HTML tags to get plain text for character count
    const plainText = content.replace(/<[^>]*>/g, '');
    
    if (plainText.length <= CHAR_LIMIT) {
      setOriginalCopywriting(content);
    } else {
      // Only show toast and truncate once
      if (originalCopywriting !== plainText.slice(0, CHAR_LIMIT)) {
        const truncatedText = plainText.slice(0, CHAR_LIMIT);
        setOriginalCopywriting(truncatedText);
        toast.error(`Content exceeds ${CHAR_LIMIT} character limit. Content has been truncated.`);
      }
    }
  };
  

  const handleRegenerateCopywriting = async () => {
    if (!selectedCopyProfile) {
      toast.error('Please select a profile first');
      return;
    }
  
    setIsRegeneratingCopy(true);
    try {
      const profileData = await loadProfileData(auth.user.uid, selectedCopyProfile.value);
      
      const query = `Original Ad Copy: ${originalCopywriting}. 
      Product Information: Product Name: ${profileData.productName}, 
      Product USP: ${profileData.productUSP}, 
      Product Ingredients: ${profileData.productIngredients}, 
      Product Content: ${profileData.productContent},
      Product Certification: ${profileData.productCert}`;
      
      let id 
      if (selectedLanguage === "Chinese") {
        id = "copywriting-regenerate-cn"
      } else if (selectedLanguage === "English") {
        id = "copywriting-regenerate-en"
      } else if (selectedLanguage === "Malay") {
        id = "copywriting-regenerate-malay"
      }

      let credit = 0.5
      let payload = { id, query, credit }
  
      const newCopywriting = await toast.promise(
        fetchDataWithoutTyping(payload, auth),
        {
          loading: "Regenerating copywriting...",
          success: "Copywriting regenerated!",
          error: "Failed to regenerate copywriting.",
        }
      );
  
      setRegeneratedCopywriting(newCopywriting);
    } catch (error) {
      console.error('Error regenerating copywriting:', error);
      toast.error(error.message || 'Failed to regenerate copywriting');
    } finally {
      setIsRegeneratingCopy(false);
    }
  };

  const handleGenerateMoreIdeas = async () => {
    if (!selectedCopyProfile) {
      toast.error('Please select a profile first');
      return;
    }
  
    setIsGeneratingIdeas(true);
    try {
      const profileData = await loadProfileData(auth.user.uid, selectedCopyProfile.value);
      
      const query = `Generate a completely different sales angle and copy ideas for this product. 
      Each idea should have a unique approach and emotional appeal.
      
      Product Details:
      - Name: ${profileData.productName}
      - USP: ${profileData.productUSP}
      - Ingredients: ${profileData.productIngredients}
      - Key Features: ${profileData.productContent}
      - Certifications: ${profileData.productCert}
  
      Previous content to avoid repeating (generate completely different angles):
      ${regeneratedCopywriting}
  
      Requirements:
      1. Each idea should have a distinct sales angle
      2. Include emotional hooks and call-to-actions
      3. Focus on different benefits or features
      4. Use varied writing styles
      5. Format each idea with a clear title and content`;
  
      let id 
      if (selectedLanguage === "Chinese") {
        id = "idea-generator-cn"
      } else if (selectedLanguage === "English") {
        id = "idea-generator-en"
      } else if (selectedLanguage === "Malay") {
        id = "idea-generator-malay"
      }

      const credit = 0.5;
      const payload = { id, query, credit };
  
      const newIdeas = await toast.promise(
        fetchDataWithoutTyping(payload, auth),
        {
          loading: "Generating new ideas...",
          success: "New ideas generated!",
          error: "Failed to generate ideas.",
        }
      );
  
      // Format the response with proper HTML paragraphs
      const formattedIdeas = newIdeas
        .split('\n')
        .filter(line => line.trim())
        .map(line => `<p>${line}</p>`)
        .join('');

      // Format and append to regenerated content with clear separation
      const separator = `
        <div style="text-align: center; font-weight: bold; margin: 20px 0;">
          ============== More Ideas ==============
        </div>
      `;
      
      setRegeneratedCopywriting(prev => prev + separator + formattedIdeas);
      
    } catch (error) {
      console.error('Error generating ideas:', error);
      toast.error(error.message || 'Failed to generate ideas');
    } finally {
      setIsGeneratingIdeas(false);
    }
  };

  const handleExtractAngleUSP = async () => {
    if (!originalCopywriting) {
      toast.error('No copywriting content to analyze');
      return;
    }
  
    setIsRegeneratingCopy(true);
    try {
      const query = `Analyze this ad copy and extract the key elements. Format your response in the following structure:
        USP (Unique Selling Propositions):
        - List each USP in bullet points
        - One USP per line
        
        Content Angles:
        - List each content angle in bullet points
        - One angle per line
        
        Emotional Triggers:
        - List emotional triggers used
        - One per line
        
        Ad Copy to analyze: ${originalCopywriting}`;
      
      let id 
      if (selectedLanguage === "Chinese") {
        id = "angle-usp-extract-cn"
      } else if (selectedLanguage === "English") {
        id = "angle-usp-extract-en"
      } else if (selectedLanguage === "Malay") {
        id = "angle-usp-extract-malay"
      }

      const credit = 0.2
      const payload = { id, query, credit }
  
      const analysis = await toast.promise(
        fetchDataWithoutTyping(payload, auth),
        {
          loading: "Analyzing content...",
          success: "Analysis complete!",
          error: "Failed to analyze content.",
        }
      );
  
      // Format the response with HTML tags for proper rendering
      const formattedAnalysis = analysis
        .replace(/USP \(Unique Selling Propositions\):/g, '<h3>USP (Unique Selling Propositions):</h3>')
        .replace(/Content Angles:/g, '<h3>Content Angles:</h3>')
        .replace(/Emotional Triggers:/g, '<h3>Emotional Triggers:</h3>')
        .replace(/- /g, '<br>• ')
        .replace(/\n/g, '<br>');
  
      const separator = '<br><br><div style="text-align: center; font-weight: bold; margin: 20px 0;">============== Analysis ==============</div><br>';
      const updatedContent = `${separator}${formattedAnalysis}`;
      setRegeneratedCopywriting(updatedContent);
  
    } catch (error) {
      console.error('Error analyzing content:', error);
      toast.error(error.message || 'Failed to analyze content');
    } finally {
      setIsRegeneratingCopy(false);
    }
  };

  const handleCopyContent = async (content) => {
    try {
      // Format content similar to how we generate it
      const formattedContent = content
        .replace(/<p>/g, '') // Remove opening p tags
        .replace(/<\/p>/g, '\n') // Convert closing p tags to newlines
        .replace(/<div[^>]*>/g, '\n') // Convert div openings to newlines
        .replace(/<\/div>/g, '\n') // Convert div closings to newlines
        .replace(/<br\s*\/?>/g, '\n') // Convert br tags to newlines
        .replace(/&nbsp;/g, ' ') // Convert &nbsp; to spaces
        .replace(/<[^>]*>/g, '') // Remove any remaining HTML tags
        .replace(/\n\s*\n/g, '\n\n') // Remove excessive blank lines
        .replace(/^\s+|\s+$/g, '') // Trim whitespace
        .replace(/•/g, '  •') // Add spacing before bullets
        .replace(/=+/g, '='); // Preserve separator lines
  
      // Create blob with both HTML and plain text versions
      const htmlContent = `
        <html>
          <body>
            <pre style="font-family: Arial, sans-serif; white-space: pre-wrap;">${formattedContent}</pre>
          </body>
        </html>
      `;
  
      // Create ClipboardItem with both formats
      const clipboardItem = new ClipboardItem({
        'text/html': new Blob([htmlContent], { type: 'text/html' }),
        'text/plain': new Blob([formattedContent], { type: 'text/plain' })
      });
  
      await navigator.clipboard.write([clipboardItem]);
      toast.success('Content copied to clipboard!');
    } catch (err) {
      console.error('Failed to copy:', err);
      // Fallback to basic text copying
      try {
        // Use the same formatting for fallback
        const plainText = content
          .replace(/<p>/g, '')
          .replace(/<\/p>/g, '\n')
          .replace(/<div[^>]*>/g, '\n')
          .replace(/<\/div>/g, '\n')
          .replace(/<br\s*\/?>/g, '\n')
          .replace(/&nbsp;/g, ' ')
          .replace(/<[^>]*>/g, '')
          .replace(/\n\s*\n/g, '\n\n')
          .replace(/^\s+|\s+$/g, '')
          .replace(/•/g, '  •')
          .replace(/=+/g, '=');
  
        await navigator.clipboard.writeText(plainText);
        toast.success('Content copied to clipboard (plain text)!');
      } catch (fallbackErr) {
        toast.error('Failed to copy content');
      }
    }
  };

  const downloadCopywriting = () => {
    if (!regeneratedCopywriting) {
      toast.error('No regenerated copywriting to download');
      return;
    }
  
    // Create a blob with the copywriting content
    const blob = new Blob([regeneratedCopywriting], { type: 'text/plain' });
    // Create a download link and trigger it
    saveAs(blob, `copywriting_${Date.now()}.txt`);
    toast.success('Copywriting downloaded successfully!');
  };

  return (
    <Section>
      <Container>
        <SectionHeader
          title="Copywriting Regenerator"
          subtitle="Regenerate your copywriting with AI"
          size={4}
          textAlign="center"
        />
        <Toaster position="bottom-right" reverseOrder={false} />

        {/* Profile Select */}
        <Box mb={3}>
          <MemoizedSelect
            isClearable
            placeholder="Select profile..."
            options={profiles}
            value={selectedCopyProfile}
            onChange={setSelectedCopyProfile}
          />
        </Box>

        <Grid container spacing={4}>
          {/* Left Grid */}
          <Grid item xs={12} md={6}>
            <Paper style={{ minHeight: '300px', height: '650px' }}>
              <Box display="flex" justifyContent="space-between" alignItems="center" p={2}>
                <Typography variant="h5">Original Copywriting</Typography>
                <Typography variant="caption" color="textSecondary">
                  {originalCopywriting.replace(/<[^>]*>/g, '').length}/{CHAR_LIMIT} characters
                </Typography>
              </Box>
              <Divider />
              <Box 
                p={3}
                style={{ 
                  height: 'calc(100% - 70px)', 
                  overflowY: 'auto',
                  cursor: 'text',
                }}
                onClick={(e) => {
                  const editorElement = e.currentTarget.querySelector('[contenteditable="true"]');
                  if (editorElement) {
                    editorElement.focus();
                  }
                }}
              >
                <TextEditor
                  script={originalCopywriting}
                  isEditMode={true}
                  onContentChange={handleOriginalContentChange}
                  maxLength={CHAR_LIMIT}
                />
              </Box>
            </Paper>
          </Grid>

          {/* Right Grid */}
          <Grid item xs={12} md={6}>
            <Paper style={{ minHeight: '300px', height: '650px' }}>
              <Box display="flex" justifyContent="space-between" alignItems="center" p={2}>
                <Typography variant="h5">Regenerated Copywriting</Typography>
                <Box display="flex" alignItems="center" gap={1}>
                  <FormControl variant="outlined" size="small" style={{ width: 120 }}>
                    <Select
                      value={selectedLanguage}
                      onChange={(e) => setSelectedLanguage(e.target.value)}
                    >
                      <MenuItem value="Chinese">Chinese</MenuItem>
                      <MenuItem value="English">English</MenuItem>
                      <MenuItem value="Malay">Malay</MenuItem>
                    </Select>
                  </FormControl>
                  <IconButton
                    onClick={() => handleCopyContent(regeneratedCopywriting)}
                    size="small"
                    title="Copy content"
                  >
                    <FileCopyIcon fontSize="small" />
                  </IconButton>
                </Box>
              </Box>
              <Divider />
              <Box 
                p={3}
                style={{
                  height: 'calc(100% - 70px)',
                  overflowY: 'auto',
                  cursor: 'text'
                }}
                onClick={(e) => {
                  const editorElement = e.currentTarget.querySelector('[contenteditable="true"]');
                  if (editorElement) {
                    editorElement.focus();
                  }
                }}
              >
                {regeneratedCopywriting ? (
                  <TextEditor
                    script={regeneratedCopywriting}
                    isEditMode={isEditMode}
                    onContentChange={(content) => setRegeneratedCopywriting(content)}
                  />
                ) : (
                  <Typography color="textSecondary">
                    Click regenerate to create new copywriting
                  </Typography>
                )}
              </Box>
            </Paper>
          </Grid>
        </Grid>

        <Box display="flex" justifyContent="flex-end" mt={3}>
          <Button
            variant="contained"
            onClick={handleExtractAngleUSP}
            disabled={!originalCopywriting || isRegeneratingCopy}
            startIcon={<AssessmentIcon />}
            color="primary"
            style={{ marginRight: 8 }}
          >
            USP & Angle
          </Button>
          <Button
            variant="contained"
            onClick={handleGenerateMoreIdeas}
            disabled={!regeneratedCopywriting || isGeneratingIdeas}
            startIcon={isGeneratingIdeas ? <CircularProgress size={20} /> : <AutorenewIcon />}
            color="primary"
            style={{ marginRight: 8 }}
          >
            {isGeneratingIdeas ? 'Generating...' : 'More Ideas'}
          </Button>
          <Button
            variant="contained"
            onClick={handleRegenerateCopywriting}
            disabled={isRegeneratingCopy || !selectedCopyProfile}
            startIcon={isRegeneratingCopy ? <CircularProgress size={20} /> : <AutorenewIcon />}
            color="primary"
            style={{ 
              marginRight: 8,
            }}
          >
            {isRegeneratingCopy ? 'Regenerating...' : 'Regenerate'}
          </Button>
          <Button
            variant="contained"
            onClick={downloadCopywriting}
            disabled={!regeneratedCopywriting}
            startIcon={<GetAppIcon />}
            color="primary"
          >
            Download
          </Button>
        </Box>
      </Container>
    </Section>
  );
}