import React, { useState } from "react";
import AppBar from "@material-ui/core/AppBar";
import Container from "@material-ui/core/Container";
import Toolbar from "@material-ui/core/Toolbar";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Divider from "@material-ui/core/Divider";
import NightsStayIcon from "@material-ui/icons/NightsStay";
import WbSunnyIcon from "@material-ui/icons/WbSunny";
import { Language } from "@material-ui/icons";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles } from "@material-ui/core/styles";
import Section from "./Section";
import { Link } from "./../util/router";
import { useAuth } from "./../util/auth";
import { useDarkMode } from "./../util/theme";
import NavbarCredits from "./NavbarCredits";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  logo: {
    height: 35,
    marginRight: theme.spacing(2),
  },
  drawerList: {
    width: 250,
  },
  spacer: {
    flexGrow: 1,
  },
}));

function Navbar(props) {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const auth = useAuth();
  const darkMode = useDarkMode();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [menuState, setMenuState] = useState(null);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleMenuItemClick = (event, lang) => {
    i18n.changeLanguage(lang);
    setAnchorEl(null);
  };

  // Use inverted logo if specified
  // and we are in dark mode
  const logo =
    props.logoInverted && darkMode.value ? props.logoInverted : props.logo;

  const handleOpenMenu = (event, id) => {
    // Store clicked element (to anchor the menu to)
    // and the menu id so we can tell which menu is open.
    setMenuState({
      anchor: event.currentTarget,
      id,
    });
  };

  const handleCloseMenu = () => {
    setMenuState(null);
  };

  return (
    <Section bgColor={props.color} size="auto">
      <AppBar position="static" color="transparent" elevation={0}>
        <Container disableGutters={true}>
          <Toolbar>
            <Link to="/">
              <img src={logo} alt="Logo" className={classes.logo} />
            </Link>
            <div className={classes.spacer} />
            <Hidden mdUp={true} implementation="css">
              <IconButton
                onClick={() => {
                  setDrawerOpen(true);
                }}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
            </Hidden>
            <Hidden smDown={true} implementation="css">
              {/* <Button component={Link} to="/about" color="inherit">
                About
              </Button> */}
              {/* <Button component={Link} to="/contentangle" color="inherit">
                Content Angle
              </Button> */}
              {/* <Button component={Link} to="/pricing" color="inherit">
                Pricing
              </Button> */}
              {/* <Button component={Link} to="/faq" color="inherit">
                FAQ
              </Button> */}

              {!auth.user && (
                <>
                  <Button component={Link} to="/auth/signin" color="inherit">
                    {/* Sign in */}
                    {t("NAV002")}
                  </Button>
                  <Box component="span" ml={1}>
                    <Button
                      component={Link}
                      to="/auth/signup"
                      variant="contained"
                      color="primary"
                    >
                      {/* Sign up */}
                      {t("NAV003")}
                    </Button>
                  </Box>
                </>
              )}

              {auth.user &&
                (auth.user.role === "Pro" ||
                  auth.user.role === "Elite" ||
                  auth.user.role === "admin" ||
                  auth.user.role === "Trial") && (
                  <>
                    {/* <Button component={Link} to="/faq" color="inherit">
                    FAQ
                  </Button> */}
                    <NavbarCredits />

                    {/* Copywriting 3 languages usable Section */}
                    {/* ATM shown as Chinese */}
                    <Button
                      color="inherit"
                      aria-label="Apps"
                      aria-controls="app-menu"
                      aria-haspopup="true"
                      onClick={(event) => {
                        handleOpenMenu(event, "app-menu");
                      }}
                    >
                      {/* 中文文案 */}
                      {t("NAV030")}
                      {/* 文案生成 */}
                      {/* {t("NAV012")} */}
                      <ExpandMoreIcon className={classes.buttonIcon} />
                    </Button>
                    <Menu
                      id="app-menu"
                      open={
                        menuState && menuState.id === "app-menu" ? true : false
                      }
                      anchorEl={menuState && menuState.anchor}
                      getContentAnchorEl={undefined}
                      onClick={handleCloseMenu}
                      onClose={handleCloseMenu}
                      keepMounted={true}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "center",
                      }}
                    >
                      <MenuItem component={Link} to="/attn_dem">
                        {/* 文案 Attention - Demand */}
                        {t("NAV013")}
                      </MenuItem>
                      <MenuItem component={Link} to="/desc_act">
                        {/* 文案 Description - Action */}
                        {t("NAV014")}
                      </MenuItem>
                      <MenuItem component={Link} to="/simplifier">
                        {/* 文案转白话 Simplifier */}
                        {t("NAV015")}
                      </MenuItem>
                      <MenuItem component={Link} to="/usp">
                        {/* 独特卖点 USP */}
                        {t("NAV016")}
                      </MenuItem>
                      <MenuItem component={Link} to="/swot">
                        {/* 强弱危机分析 SWOT */}
                        {t("NAV017")}
                      </MenuItem>
                      <MenuItem component={Link} to="/reply">
                        {/* 回复攻略 Reply SOP */}
                        {t("NAV018")}
                      </MenuItem>
                      <MenuItem component={Link} to="/cfmenu">
                        {/* 信心回复 Confidence Reply */}
                        {t("NAV019")}
                      </MenuItem>
                      <MenuItem component={Link} to="/cfgenerator">
                        {/* 信心回复 Confidence Reply */}
                        {t("NAV036")}
                      </MenuItem>
                      <MenuItem component={Link} to="/contentanglegenerator">
                        {/* 销售角度生成 Content Angle */}
                        {t("NAV020")}
                      </MenuItem>
                      {/* <MenuItem component={Link} to="/imagecontent">
                        图片文案生成 Image Content Generator
                      </MenuItem> */}
                      <MenuItem component={Link} to="/xhs">
                        小红书文案生成 Xiao Hong Shu
                      </MenuItem>
                      <MenuItem component={Link} to="/combined_adda">
                        合成 ADDA 文案生成 Combined ADDA (V2)
                      </MenuItem>
                    </Menu>

                    {/* Malay Section */}
                    <Button
                      color="inherit"
                      aria-label="Malay"
                      aria-controls="malay-menu"
                      aria-haspopup="true"
                      onClick={(event) => {
                        handleOpenMenu(event, "malay-menu");
                      }}
                    >
                      {/* 马来文案 */}
                      {t("NAV032")}
                      <ExpandMoreIcon className={classes.buttonIcon} />
                    </Button>
                    <Menu
                      id="malay-menu"
                      open={
                        menuState && menuState.id === "malay-menu"
                          ? true
                          : false
                      }
                      anchorEl={menuState && menuState.anchor}
                      getContentAnchorEl={undefined}
                      onClick={handleCloseMenu}
                      onClose={handleCloseMenu}
                      keepMounted={true}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "center",
                      }}
                    >
                      <MenuItem component={Link} to="/malay/attn_dem">
                        {/* 文案 Attention - Demand */}
                        {t("NAV013")}
                      </MenuItem>
                      <MenuItem component={Link} to="/malay/desc_act">
                        {/* 文案 Description - Action */}
                        {t("NAV014")}
                      </MenuItem>
                      <MenuItem component={Link} to="/malay/simplifier">
                        {/* 文案转白话 Simplifier */}
                        {t("NAV015")}
                      </MenuItem>
                      <MenuItem component={Link} to="/malay/usp">
                        {/* 独特卖点 USP */}
                        {t("NAV016")}
                      </MenuItem>
                      <MenuItem component={Link} to="/malay/swot">
                        {/* 强弱危机分析 SWOT */}
                        {t("NAV017")}
                      </MenuItem>
                      <MenuItem component={Link} to="/malay/reply">
                        {/* 回复攻略 Reply SOP */}
                        {t("NAV018")}
                      </MenuItem>
                      <MenuItem component={Link} to="/malay/cfmenu">
                        {/* 信心回复 Confidence Reply */}
                        {t("NAV019")}
                      </MenuItem>
                      <MenuItem component={Link} to="/malay/cfgenerator">
                        {/* 信心回复 Confidence Reply */}
                        {t("NAV036")}
                      </MenuItem>
                      <MenuItem component={Link} to="/malay/contentanglegenerator">
                        {/* 销售角度生成 Content Angle */}
                        {t("NAV020")}
                      </MenuItem>
                      <MenuItem component={Link} to="/malay/combined_adda">
                        合成 ADDA 文案生成 Combined ADDA (V2)
                      </MenuItem>
                    </Menu>

                    {/* English Section */}
                    <Button
                      color="inherit"
                      aria-label="English"
                      aria-controls="eng-menu"
                      aria-haspopup="true"
                      onClick={(event) => {
                        handleOpenMenu(event, "eng-menu");
                      }}
                    >
                      {/* 英文文案 */}
                      {t("NAV031")}
                      <ExpandMoreIcon className={classes.buttonIcon} />
                    </Button>
                    <Menu
                      id="eng-menu"
                      open={
                        menuState && menuState.id === "eng-menu" ? true : false
                      }
                      anchorEl={menuState && menuState.anchor}
                      getContentAnchorEl={undefined}
                      onClick={handleCloseMenu}
                      onClose={handleCloseMenu}
                      keepMounted={true}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "center",
                      }}
                    >
                      <MenuItem component={Link} to="/eng/attn_dem">
                        {/* 文案 Attention - Demand */}
                        {t("NAV013")}
                      </MenuItem>
                      <MenuItem component={Link} to="/eng/desc_act">
                        {/* 文案 Description - Action */}
                        {t("NAV014")}
                      </MenuItem>
                      <MenuItem component={Link} to="/eng/simplifier">
                        {/* 文案转白话 Simplifier */}
                        {t("NAV015")}
                      </MenuItem>
                      <MenuItem component={Link} to="/eng/usp">
                        {/* 独特卖点 USP */}
                        {t("NAV016")}
                      </MenuItem>
                      <MenuItem component={Link} to="/eng/swot">
                        {/* 强弱危机分析 SWOT */}
                        {t("NAV017")}
                      </MenuItem>
                      <MenuItem component={Link} to="/eng/reply">
                        {/* 回复攻略 Reply SOP */}
                        {t("NAV018")}
                      </MenuItem>
                      <MenuItem component={Link} to="/eng/cfmenu">
                        {/* 信心回复 Confidence Reply */}
                        {t("NAV019")}
                      </MenuItem>
                      <MenuItem component={Link} to="/eng/cfgenerator">
                        {/* 信心回复 Confidence Reply */}
                        {t("NAV036")}
                      </MenuItem>
                      <MenuItem component={Link} to="/eng/contentanglegenerator">
                        {/* 销售角度生成 Content Angle */}
                        {t("NAV020")}
                      </MenuItem>
                      <MenuItem component={Link} to="/eng/combined_adda">
                        合成 ADDA 文案生成 Combined ADDA (V2)
                      </MenuItem>
                    </Menu>

                    <Button
                      color="inherit"
                      aria-label="Voice"
                      aria-controls="voice-menu"
                      aria-haspopup="true"
                      onClick={(event) => {
                        handleOpenMenu(event, "voice-menu");
                      }}
                    >
                      语音
                      <ExpandMoreIcon className={classes.buttonIcon} />
                    </Button>
                    <Menu
                      id="voice-menu"
                      open={
                        menuState && menuState.id === "voice-menu" ? true : false
                      }
                      anchorEl={menuState && menuState.anchor}
                      getContentAnchorEl={undefined}
                      onClick={handleCloseMenu}
                      onClose={handleCloseMenu}
                      keepMounted={true}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "center",
                      }}
                    >
                      <MenuItem component={Link} to="/text_to_speech">
                        文本转语音 (V2)
                      </MenuItem>
                      <MenuItem component={Link} to="/songcreation"> 
                        歌曲创作 (V2)
                      </MenuItem>
                    </Menu>

                    {/* Image Section */}
                    <Button
                      color="inherit"
                      aria-label="Image"
                      aria-controls="image-menu"
                      aria-haspopup="true"
                      onClick={(event) => {
                        handleOpenMenu(event, "image-menu");
                      }}
                    >
                      {/* 图片 */}
                      {t("NAV021")}
                      <ExpandMoreIcon className={classes.buttonIcon} />
                    </Button>
                    <Menu
                      id="app-menu"
                      open={
                        menuState && menuState.id === "image-menu"
                          ? true
                          : false
                      }
                      anchorEl={menuState && menuState.anchor}
                      getContentAnchorEl={undefined}
                      onClick={handleCloseMenu}
                      onClose={handleCloseMenu}
                      keepMounted={true}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "center",
                      }}
                    >
                      <MenuItem component={Link} to="/image">
                        {/* 图片生成 Image */}
                        {t("NAV022")}
                      </MenuItem>
                      <MenuItem component={Link} to="/replace">
                        {/* 产品图生成 Background */}
                        {t("NAV023")}
                      </MenuItem>
                      <MenuItem component={Link} to="/rembg">
                        {/* 去除背景 */}
                        {t("NAV035")}
                      </MenuItem>
                      <MenuItem component={Link} to="/color-palette">
                        {/* 色系生成 Color Palette */}
                        {t("NAV024")}
                      </MenuItem>
                    </Menu>
                  </>
                )}

              {auth.user &&
                ( auth.user.role === "Pro" ||
                  auth.user.role === "Elite" ||
                  auth.user.role === "admin" ||
                  auth.user.role === "Trial") && (
                  <>
                    {/* Video Section */}
                    <Button
                      color="inherit"
                      aria-label="video"
                      aria-controls="video-menu"
                      aria-haspopup="true"
                      onClick={(event) => {
                        handleOpenMenu(event, "video-menu");
                      }}
                    >
                      {/* 剧本 */}
                      {t("NAV025")}
                      <ExpandMoreIcon className={classes.buttonIcon} />
                    </Button>
                    <Menu
                      id="app-menu"
                      open={
                        menuState && menuState.id === "video-menu"
                          ? true
                          : false
                      }
                      anchorEl={menuState && menuState.anchor}
                      getContentAnchorEl={undefined}
                      onClick={handleCloseMenu}
                      onClose={handleCloseMenu}
                      keepMounted={true}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "center",
                      }}
                    >
                      {/* <MenuItem component={Link} to="/reels">
                        Reels剧本
                        {t("NAV034")}
                      </MenuItem> */}
                      <MenuItem component={Link} to="/video/idea">
                        {/* 剧本大纲 Idea */}
                        {t("NAV026")}
                      </MenuItem>
                      <MenuItem component={Link} to="/combined_pepc">
                        合成 PEPC 剧本生成 Combined PEPC (V2)
                      </MenuItem>
                      {/* <MenuItem component={Link} to="/video/pepc-p"> */}
                        {/* 剧本营销 PEPC - P */}
                        {/* {t("NAV027")} */}
                      {/* </MenuItem> */}
                    </Menu>
                  </>
                )}

              {auth.user &&
                (auth.user.role === "Pro" ||
                  auth.user.role === "Elite" ||
                  auth.user.role === "admin" ||
                  auth.user.role === "Trial") && (
                  <>
                    {/* Scraper Section */}
                    <Button
                      color="inherit"
                      aria-label="ad"
                      aria-controls="ad-menu"
                      aria-haspopup="true"
                      onClick={(event) => {
                        handleOpenMenu(event, "ad-menu");
                      }}
                    >
                      {/* Ad Analyzer */}
                      {t("NAV028")}
                      <ExpandMoreIcon className={classes.buttonIcon} />
                    </Button>
                    <Menu
                      id="app-menu"
                      open={
                        menuState && menuState.id === "ad-menu" ? true : false
                      }
                      anchorEl={menuState && menuState.anchor}
                      getContentAnchorEl={undefined}
                      onClick={handleCloseMenu}
                      onClose={handleCloseMenu}
                      keepMounted={true}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "center",
                      }}
                    >
                      <MenuItem component={Link} to="/ad-analyzer">
                        {/* Ad Analyzer */}
                        {t("NAV029")}
                      </MenuItem>
                      <MenuItem component={Link} to="/ad-dashboard">
                        {/* Data History */}
                        {t("NAV037")}
                      </MenuItem>
                      <MenuItem component={Link} to="/regenerator/cw">
                        {/* Copywriting Regenerator */}
                        {t("NAV038")}
                      </MenuItem>
                      <MenuItem component={Link} to="/regenerator/usp">
                        {/* USP Regenerator */}
                        {t("NAV039")}
                      </MenuItem>
                      <MenuItem component={Link} to="/regenerator/video">
                        {/* Video Regenerator */}
                        {t("NAV040")}
                      </MenuItem>
                      <MenuItem component={Link} to="/regenerator/angle">
                        {/* Angle Regenerator */}
                        {t("NAV041")}
                      </MenuItem>
                      <MenuItem component={Link} to="/regenerator/reply">
                        {/* Reply Regenerator */}
                        {t("NAV042")}
                      </MenuItem>
                    </Menu>
                  </>
                )}

              {/* Prompt user to purchase a plan if they are no either an Elite or Pro */}
              {auth.user && auth.user.role === "No Role" && (
                <>
                  <Button component={Link} to="/payment" color="inherit">
                    {/* Purchase a Plan Right Now! */}
                    {t("NAV001")}
                  </Button>
                </>
              )}

              {/* General Navigation */}
              {auth.user && (
                <>
                  {/* Account Section */}
                  <Button
                    color="inherit"
                    aria-label="Account"
                    aria-controls="account-menu"
                    aria-haspopup="true"
                    onClick={(event) => {
                      handleOpenMenu(event, "account-menu");
                    }}
                  >
                    {/* Account */}
                    {t("NAV004")}
                    <ExpandMoreIcon className={classes.buttonIcon} />
                  </Button>
                  <Menu
                    id="account-menu"
                    open={
                      menuState && menuState.id === "account-menu"
                        ? true
                        : false
                    }
                    anchorEl={menuState && menuState.anchor}
                    getContentAnchorEl={undefined}
                    onClick={handleCloseMenu}
                    onClose={handleCloseMenu}
                    keepMounted={true}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "center",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                  >
                    {/* <MenuItem component={Link} to="/dashboard">
                      Dashboard
                    </MenuItem> */}
                    {auth.user &&
                      (auth.user.role === "Elite" ||
                        auth.user.role === "Pro" ||
                        auth.user.role === "admin" ||
                        auth.user.role === "Trial") && (
                        <>
                          <MenuItem component={Link} to="/tutorial">
                            {/* Tutorial */}
                            {t("NAV005")}
                          </MenuItem>
                          <MenuItem component={Link} to="/profile">
                            {/* Profiles */}
                            {"Profiles (V2)"}
                          </MenuItem>
                          <MenuItem component={Link} to="/user-history">
                            {/* History */}
                            {t("NAV006")}
                          </MenuItem>
                          <MenuItem>
                            <a
                              href="https://ctg-editor.netlify.app/"
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{
                                textDecoration: "none",
                                color: "inherit",
                              }}
                            >
                              {/* Editor */}
                              {t("NAV033")}
                            </a>
                          </MenuItem>
                          {/* <MenuItem component={Link} to="/content-management">
                          {t("NAV006")}
                        </MenuItem> */}
                          <MenuItem component={Link} to="/savedreply">
                            {/* Saved Reply */}
                            {t("NAV007")}
                          </MenuItem>
                          <MenuItem component={Link} to="/announcement">
                            {/* Updates */}
                            {t("NAV008")}
                          </MenuItem>
                        </>
                      )}
                    {auth.user &&
                      auth.user.role !== "Elite" &&
                      auth.user.role !== "Pro" &&
                      auth.user.role !== "admin" && (
                        <MenuItem component={Link} to="/payment">
                          {/* Pricing Plan */}
                          {t("NAV009")}
                        </MenuItem>
                      )}
                    <MenuItem component={Link} to="/settings">
                      {/* User */}
                      {t("NAV010")}
                    </MenuItem>
                    <Divider />
                    <MenuItem
                      onClick={(event) => {
                        auth.signout();
                      }}
                    >
                      {/* Logout */}
                      {t("NAV011")}
                    </MenuItem>
                  </Menu>
                </>
              )}

              {/* Language Switcher */}
              <IconButton
                aria-controls="language-menu"
                aria-haspopup="true"
                onClick={(event) => setAnchorEl(event.currentTarget)}
              >
                <Language />
              </IconButton>
              <Menu
                id="language-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
              >
                <MenuItem onClick={(event) => handleMenuItemClick(event, "cn")}>
                  中文
                </MenuItem>
                <MenuItem onClick={(event) => handleMenuItemClick(event, "en")}>
                  English
                </MenuItem>
                <MenuItem
                  onClick={(event) => handleMenuItemClick(event, "malay")}
                >
                  Melayu
                </MenuItem>
              </Menu>
              {/* Light/Dark Mode */}
              <IconButton
                color="inherit"
                onClick={darkMode.toggle}
                style={{ opacity: 0.6 }}
              >
                {darkMode.value && <NightsStayIcon />}

                {!darkMode.value && <WbSunnyIcon />}
              </IconButton>
            </Hidden>
          </Toolbar>
        </Container>
      </AppBar>

      {/* Mobile */}
      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
      >
        <List
          className={classes.drawerList}
          onClick={() => setDrawerOpen(false)}
        >
          {!auth.user && (
            <>
              <ListItem component={Link} to="/auth/signin" button={true}>
                <ListItemText>
                  {/* Sign in */}
                  {t("NAV002")}
                </ListItemText>
              </ListItem>
              <ListItem>
                <Button
                  component={Link}
                  to="/auth/signup"
                  variant="contained"
                  color="primary"
                >
                  {/* Sign up */}
                  {t("NAV003")}
                </Button>
              </ListItem>
            </>
          )}
          {auth.user &&
            (auth.user.role === "Pro" ||
              auth.user.role === "Elite" ||
              auth.user.role === "admin") && (
              <>
                <NavbarCredits />
                <Divider />
                <ListItem component={Link} to="/attn_dem" button={true}>
                  <ListItemText>
                    {/* 文案 Attention - Demand */}
                    {t("NAV013")}
                  </ListItemText>
                </ListItem>
                <ListItem component={Link} to="/desc_act" button={true}>
                  <ListItemText>
                    {/* 文案 Description - Action */}
                    {t("NAV014")}
                  </ListItemText>
                </ListItem>
                <ListItem component={Link} to="/malay/attn_dem" button={true}>
                  <ListItemText>
                    {/* 文案 Attention - Demand */}
                    {"马来"}
                    {t("NAV013")}
                  </ListItemText>
                </ListItem>
                <ListItem component={Link} to="/malay/desc_act" button={true}>
                  <ListItemText>
                    {/* 文案 Description - Action */}
                    {"马来"}
                    {t("NAV014")}
                  </ListItemText>
                </ListItem>
                <ListItem component={Link} to="/eng/attn_dem" button={true}>
                  <ListItemText>
                    {/* 文案 Attention - Demand */}
                    {"英文"}
                    {t("NAV013")}
                  </ListItemText>
                </ListItem>
                <ListItem component={Link} to="/eng/desc_act" button={true}>
                  <ListItemText>
                    {/* 文案 Description - Action */}
                    {"英文"}
                    {t("NAV014")}
                  </ListItemText>
                </ListItem>
                <ListItem component={Link} to="/usp" button={true}>
                  <ListItemText>
                    {/* 独特卖点 USP */}
                    {t("NAV016")}
                  </ListItemText>
                </ListItem>
                <ListItem component={Link} to="/swot" button={true}>
                  <ListItemText>
                    {/* 强弱危机分析 SWOT */}
                    {t("NAV017")}
                  </ListItemText>
                </ListItem>
                <ListItem component={Link} to="/reply" button={true}>
                  <ListItemText>
                    {/* 回复攻略 Reply SOP */}
                    {t("NAV018")}
                  </ListItemText>
                </ListItem>
                <ListItem
                  component={Link}
                  to="/contentanglegenerator"
                  button={true}
                >
                  <ListItemText>
                    {/* 销售角度生成 Content Angle Generator */}
                    {t("NAV020")}
                  </ListItemText>
                </ListItem>
                <Divider />
                <ListItem component={Link} to="/user-history" button={true}>
                  <ListItemText>
                    {/* History */}
                    {t("NAV006")}
                  </ListItemText>
                </ListItem>
                <ListItem component={Link} to="/announcement" button={true}>
                  <ListItemText>
                    {/* Updates */}
                    {t("NAV008")}
                  </ListItemText>
                </ListItem>
              </>
            )}
          {auth.user && auth.user.role === "No Role" && (
            <>
              <ListItem component={Link} to="/payment" button={true}>
                <ListItemText>
                  {/* Purchase a Plan Right Now! */}
                  {t("NAV001")}
                </ListItemText>
              </ListItem>
            </>
          )}
          {auth.user && (
            <>
              <ListItem component={Link} to="/settings" button={true}>
                <ListItemText>
                  {/* User */}
                  {t("NAV010")}
                </ListItemText>
              </ListItem>
              <Divider />

              <ListItem
                button={true}
                onClick={(event) => {
                  auth.signout();
                }}
              >
                <ListItemText>
                  {/* Logout */}
                  {t("NAV011")}
                </ListItemText>
              </ListItem>
            </>
          )}

          <ListItem>
            <IconButton
              color="inherit"
              onClick={darkMode.toggle}
              style={{ opacity: 0.6 }}
            >
              {darkMode.value && <NightsStayIcon />}

              {!darkMode.value && <WbSunnyIcon />}
            </IconButton>

            {/* Mobile Language Switcher */}
            <IconButton
              aria-controls="language-menu"
              aria-haspopup="true"
              onClick={(event) => setAnchorEl(event.currentTarget)}
            >
              <Language />
            </IconButton>
            <Menu
              id="language-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={() => setAnchorEl(null)}
            >
              <MenuItem onClick={(event) => handleMenuItemClick(event, "cn")}>
                中文
              </MenuItem>
              <MenuItem onClick={(event) => handleMenuItemClick(event, "en")}>
                English
              </MenuItem>
              <MenuItem
                onClick={(event) => handleMenuItemClick(event, "malay")}
              >
                Melayu
              </MenuItem>
            </Menu>
          </ListItem>
        </List>
      </Drawer>
    </Section>
  );
}

export default Navbar;
